import React, { Component } from 'react';
import Menu from './menu';

export default class Dashboard extends Component {
  render() {

    return (
      <div>
        <Menu />
      </div>
    )
  }
}