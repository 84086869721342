import React, { useEffect } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons'
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';
import api from './services/api';

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const useStyles = makeStyles((theme) => ({
  root: {
    textAlign: '-webkit-center',
    backgroundColor: '#EBE5FA',
  },
  content: {
    width: '80%',
    display: 'flex',
    justifyContent: 'space-around',
    marginTop: 30,
    marginBottom: 30
  },
  imageContent: {
    width: '50%'
  },
  textContent: {
    width: '50%',
    marginLeft: 30,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between'
  },
  title: {
    fontFamily: 'Quicksand, sans-serif',
    fontSize: 25,
    textAlign: 'end'
  },
  resumo: {
    fontFamily: 'garamond',
    textAlign: 'end',
  },
  button: {
    marginLeft: 10,
    width: 60,
    borderRadius: 10,
    backgroundColor: "#241F56"
  },
  img: {
    minHeight: '350px',
    maxHeight: '350px',
    maxWidth: 600,
    width: '100%',
    borderRadius: 5,
    boxShadow: '-4px -4px 5px #424242'
  },
}));

export default function MediaCard() {
  const [tutorialSteps, setTutorialSteps] = React.useState({});
  const [arrayMap, setArrayMap] = React.useState(false)
  const classes = useStyles();
  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);
  const maxSteps = tutorialSteps.length;

  useEffect(async () => {
    await api.get("/slideIndex")
      .then(response => {
        setTutorialSteps(response.data.slideIndex)
      })
      .then(response => {
        setArrayMap(true)
      })
      .catch(err => {
        console.log(err);
      });
  }, []);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStepChange = (step) => {
    setActiveStep(step);
  };
  return (
    <div >
      <div className='News'>
        <AutoPlaySwipeableViews
          axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
          index={activeStep}
          onChangeIndex={handleStepChange}
          enableMouseEvents
          interval={8000}
          style={{ WebkitTransition: 'opacity 1s !important' }}
        >
          {(arrayMap == false ? [] : tutorialSteps).map((step, index) => (
            <div key={step.title}>
              {Math.abs(activeStep - index) <= 2 ? (
                <div className='news-container flex horizontal'>
                  <div className='flex-1 img-container'>
                    <img src={step.url} />
                  </div>

                  <div className='news-text-container flex-1 flex vertical'>
                    <div>
                      <p className='news-title'>{step.title}</p>
                      <p className='news-resumo'>{step.resumo} </p>
                    </div>
                    <div>
                      <button onClick={handleBack} style={activeStep === 0 ? { display: 'none' } : null} className={classes.button}><FontAwesomeIcon color="#A7EC4D" icon={faChevronLeft} /></button>
                      <button onClick={handleNext} style={activeStep === maxSteps - 1 ? { display: 'none' } : null} className={classes.button}><FontAwesomeIcon color="#A7EC4D" icon={faChevronRight} /></button>

                    </div>
                  </div>
                </div>
              ) : null}
            </div>
          ))}
        </AutoPlaySwipeableViews>
      </div>

    </div>

  );
}
