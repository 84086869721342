import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import PdfPremiacao from './ComissaodePremiacao.pdf';
import PdfSelecao from './ComissaodeSelecao.pdf';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
}));

function ListItemLink(props) {
  return <ListItem button component="a" {...props} />;
}

export default function SimpleList() {
  const classes = useStyles();


  return (
    <div className={classes.root}>
      <List component="nav" aria-label="main mailbox folders">
        <ListItemLink href={PdfPremiacao} target="_blank">
          <PictureAsPdfIcon style={{ color: 'red' }} />
          <ListItemText primary="Comissão de Premiação" />
        </ListItemLink>

        <ListItemLink href={PdfSelecao} target="_blank">
          <PictureAsPdfIcon style={{ color: 'red' }} />
          <ListItemText primary="Comissão de Seleção" />
        </ListItemLink>
      </List>
    </div>
  );
}