import React, { Component } from 'react';
import './style.css';

export default class Footer extends Component {
  render() {
    return <div>
      <div className="footer">
        <div className="contato">
          Para mais informações:<br></br>
          (62) 39021089<br></br>
          salaoanapolinodearte@gmail.com<br></br><br></br>
        </div>

        <img src="/img/logo-prefeitura-de-anapolis---eleitoral(PRETO).png" alt="logo" className="logo" />
      </div>
    </div>;
  }
}