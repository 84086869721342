import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import NoteIcon from '@material-ui/icons/Note';
import EventIcon from '@material-ui/icons/Event';
import GetAppIcon from '@material-ui/icons/GetApp';
import TheatersIcon from '@material-ui/icons/Theaters';
import PostAddIcon from '@material-ui/icons/PostAdd';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import HomeIcon from '@material-ui/icons/Home';
import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
import YouTubeIcon from '@material-ui/icons/YouTube';
import './style.css';

import { Link } from 'react-router-dom'

const useStyles = makeStyles({
  list: {
    width: 250,
  },
  fullList: {
    width: true,
  },
});


export default function SwipeableTemporaryDrawer() {
  const classes = useStyles();
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === 'top' || anchor === 'bottom',
      })}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List>
        <Link style={{ textDecoration: 'none', color: 'black' }} to="/mostradeteatro">
          <ListItem button>
            <ListItemIcon>
              <HomeIcon />
            </ListItemIcon>
            <ListItemText primary={'Início'} />
          </ListItem>
        </Link>
        <Link style={{ textDecoration: 'none', color: 'black' }} to="/mostradeteatro/publicacoes">
          <ListItem button>
            <ListItemIcon>
              <NoteIcon />
            </ListItemIcon>
            <ListItemText primary={'Publicações'} />
          </ListItem>
        </Link>

        <Link style={{ textDecoration: 'none', color: 'black' }} to="/mostradeteatro/programacao">
          <ListItem button>
            <ListItemIcon>
              <EventIcon />
            </ListItemIcon>
            <ListItemText primary={'Programação'} />
          </ListItem>
        </Link>

        <Link style={{ textDecoration: 'none', color: 'black' }} to="/mostradeteatro/downloads">
          <ListItem button>
            <ListItemIcon>
              <GetAppIcon />
            </ListItemIcon>
            <ListItemText primary={'Downloads'} />
          </ListItem>
        </Link>

        <Link style={{ textDecoration: 'none', color: 'black' }} to="/mostradeteatro/oficinas">
          <ListItem button>
            <ListItemIcon>
              <TheatersIcon />
            </ListItemIcon>
            <ListItemText primary={'Oficinas'} />
          </ListItem>
        </Link>

        <Link style={{ textDecoration: 'none', color: 'black' }} to="/mostradeteatro/inscricoes">
          <ListItem button>
            <ListItemIcon>
              <PostAddIcon />
            </ListItemIcon>
            <ListItemText primary={'Inscrições'} />
          </ListItem>
        </Link>

      </List>
      <Divider />
      <List>

        <Link style={{ textDecoration: 'none', color: 'black' }} to="/mostradeteatro/inscricoes">
          <ListItem button>
            <ListItemIcon>
              <ExitToAppIcon />
            </ListItemIcon>
            <ListItemText primary={'Sair'} />
          </ListItem>
        </Link>
      </List>
    </div >
  );

  return (
    <div className="mostra-navbar">
      <div>
        {['Abrir Menu'].map((anchor) => (
          <React.Fragment key={anchor}>
            <Button className="btn-menu" onClick={toggleDrawer(anchor, true)}>{anchor}</Button>
            <SwipeableDrawer
              anchor={anchor}
              open={state[anchor]}
              onClose={toggleDrawer(anchor, false)}
              onOpen={toggleDrawer(anchor, true)}
            >
              {list(anchor)}
            </SwipeableDrawer>
          </React.Fragment>
        ))}

        <div className="rede-social">
          <a href="https://www.facebook.com">
            <FacebookIcon style={{ color: 'white' }} />
          </a>
          <a href="https://www.instagram.com">
            <InstagramIcon style={{ color: 'white' }} />
          </a>
          <a href="https://www.youtube.com">
            <YouTubeIcon style={{ color: 'white' }} />
          </a>
        </div>
      </div>


    </div >


  );
}