import React, { Component } from 'react';
import './style.css';
import 'bootstrap/dist/css/bootstrap.min.css';

export default class Instrucoes extends Component {
  render() {
    return (
      <div className="instrucoes">
        <ul><b>
          <li>O sistema de inscrição funciona melhor com o navegador Firefox.<br></br></li>
          <li>Faça a leitura do Regulamento disponível em Publicações.<br></br></li>
          <li>É necessário fazer download dos modelos para preencher Currículo, Projeto e Ficha de inscrição.<br></br></li>
          <li>A inscrição não poderá ser preenchida e salva em etapas. Recomenda-se reunir previamente todos os dados e arquivos exigidos pelo regulamento para que a inscrição seja preenchida. Depois de enviados, não será possível a alteração dos dados.<br></br></li>
          <li>Só é possível anexar um único arquivo PDF em cada campo do sistema de inscrição.<br></br></li>
          <li>Não é possível fazer mais de uma inscrição no mesmo computador simultaneamente.<br></br></li>
          <li>A inscrição só é concluída ao chegar à etapa de confirmação do site.<br></br></li>
        </b></ul>
      </div>
    )
  }
}