import React from 'react';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import { isAuthenticated } from './services/auth';
import App from './App.js'
import Inicial from './Inicial'
import FMC20 from './containers/fmc20'

import IndexLogin from './IndexLogin';
import PublicacoesOficiais from './containers/Publicacoes';
import InformesUrgentes from './containers/InformesUrgentes';
import ProdutoCultural from './containers/ProdutoCultural';
import CadastroCultura from './containers/Cadastro'

import MostradeTeatroIndex from './mostradeteatro/src/App';
import MostradeTeatroPublicacoes from './mostradeteatro/src/components/publicacoes/index';
import MostradeTeatroInscricao from './mostradeteatro/src/components/inscricao/index';
import MostradeTeatroDownloads from './mostradeteatro/src/components/downloads/index';
import MostradeTeatroEdicoesAnteriores from './mostradeteatro/src/components/edicoesAnteriores/index';

// import SalaodeArteIndex from './salaodearte/src/App';
// import SalaodeArtePublicacoes from './salaodearte/src/components/publicacoes/index';
// import SalaodeArteInscricao from './salaodearte/src/components/inscricao/index';
// import SalaodeArteDownloads from './salaodearte/src/components/downloads/index';
// import SalaodeArteEdicoesAnteriores from './salaodearte/src/components/edicoesAnteriores/index';

import Dashboard from './dashboard'

import Lives from './dashboard/listagem/lives'

import SlideIndex from './dashboard/listagem/slideIndex'
import SlideIndexCreate from './dashboard/cadastro/slideIndex'
import SlideIndexShow from './dashboard/show/slideIndex'

import SlideSalao from './dashboard/listagem/slideSalao'
import SlideSalaoCreate from './dashboard/cadastro/slideSalao'

import Calendario from './dashboard/listagem/calendario'
import CalendarioCreate from './dashboard/cadastro/calendario'

import Publicacao from './dashboard/listagem/publicacao'
import PublicacaoCreate from './dashboard/cadastro/publicacao'

import PublicacaoLei from './dashboard/listagem/publicacaoLei'
import PublicacaoLeiCreate from './dashboard/cadastro/publicacaoLei'

import CategoriasPublicacaoLei from './dashboard/listagem/categoriasPublicacaoLei'
import CategoriasPublicacaoLeiCreate from './dashboard/cadastro/categoriasPublicacaoLei'

import Download from './dashboard/listagem/download'
import DownloadCreate from './dashboard/cadastro/download'

import InscricoesSalao from './dashboard/listagem/inscricoesSalao'
import InscricoesLei from './dashboard/listagem/inscricoesLei'

import ProdutoCulturalList from './dashboard/listagem/produtoCultural'
import ProdutoCulturalCreate from './dashboard/cadastro/produtoCultural'

const PrivateRoute = ({ component: Component, ...rest }) => (
    <Route
        {...rest}
        render={props =>
            isAuthenticated() ? (
                <Component {...props} />
            ) : (
                <Redirect to={{ pathname: "/login", state: { from: props.location } }} />
            )
        }
    />
);


const Routes = () => (
    <BrowserRouter>
        <Switch>
            <Route exact path="/" component={() => <App />} />
            <Route exact path="/login" component={() => <IndexLogin />} />
            <Route exact path="/lei-aldir-blanc" component={() => <Inicial />} />
            <Route exact path="/fmc" component={() => <FMC20 />} />
            <Route exact path="/publicacoes" component={() => <PublicacoesOficiais />} />
            <Route exact path="/informes" component={() => <InformesUrgentes />} />
            <Route exact path="/produto-cultural" component={() => <ProdutoCultural />} />
            <Route exact path="/cadastro" component={() => <CadastroCultura />} />


            <Route exact path="/mostradeteatro" component={() => <MostradeTeatroIndex />} />
            <Route exact path="/mostradeteatro/publicacoes" component={() => <MostradeTeatroPublicacoes />} />
            <Route exact path="/mostradeteatro/downloads" component={() => <MostradeTeatroDownloads />} />
            <Route exact path="/mostradeteatro/edicoesAnteriores" component={() => <MostradeTeatroEdicoesAnteriores />} />
            <Route exact path="/mostradeteatro/Inscricoes" component={() => <MostradeTeatroInscricao />} />

            {/* <Route exact path="/salaodearte" component={() => <SalaodeArteIndex />} />
            <Route exact path="/salaodearte/publicacoes" component={() => <SalaodeArtePublicacoes />} />
            <Route exact path="/salaodearte/downloads" component={() => <SalaodeArteDownloads />} />
            <Route exact path="/salaodearte/edicoesAnteriores" component={() => <SalaodeArteEdicoesAnteriores />} />
            <Route exact path="/salaodearte/Inscricoes" component={() => <SalaodeArteInscricao />} /> */}

            <PrivateRoute exact path="/dashboard" component={() => <Dashboard />} />

            <PrivateRoute exact path="/dashboard/lives" component={() => <Lives />} />

            <PrivateRoute exact path="/dashboard/slideIndex" component={() => <SlideIndex />} />
            <PrivateRoute exact path="/dashboard/slideIndex/:id" component={() => <SlideIndexCreate />} />
            <PrivateRoute exact path="/dashboard/slideIndex/cadastro" component={() => <SlideIndexCreate />} />
            <PrivateRoute exact path="/dashboard/slideIndex/show" component={() => <SlideIndexShow />} />

            {/* <PrivateRoute exact path="/dashboard/slideSalao" component={() => <SlideSalao />} />
            <PrivateRoute exact path="/dashboard/slideSalao/cadastro" component={() => <SlideSalaoCreate />} /> */}

            <PrivateRoute exact path="/dashboard/calendario" component={() => <Calendario />} />
            <PrivateRoute exact path="/dashboard/calendario/cadastro" component={() => <CalendarioCreate />} />

            <PrivateRoute exact path="/dashboard/publicacoes" component={() => <Publicacao />} />
            <PrivateRoute exact path="/dashboard/publicacoes/cadastro" component={() => <PublicacaoCreate />} />

            <PrivateRoute exact path="/dashboard/publicacaoLei" component={() => <PublicacaoLei />} />
            <PrivateRoute exact path="/dashboard/publicacaoLei/cadastro" component={() => <PublicacaoLeiCreate />} />

            <PrivateRoute exact path="/dashboard/downloads" component={() => <Download />} />
            <PrivateRoute exact path="/dashboard/downloads/cadastro" component={() => <DownloadCreate />} />

            {/* <PrivateRoute exact path="/dashboard/inscricoes-salao" component={() => <InscricoesSalao />} /> */}
            <PrivateRoute exact path="/dashboard/inscricoes-lei" component={() => <InscricoesLei />} />

            <PrivateRoute exact path="/dashboard/categoriasPublicacaoLei" component={() => <CategoriasPublicacaoLei />} />
            <PrivateRoute exact path="/dashboard/categoriasPublicacaoLei/cadastro" component={() => <CategoriasPublicacaoLeiCreate />} />

            <PrivateRoute exact path="/dashboard/produtoCultural" component={() => <ProdutoCulturalList />} />
            <PrivateRoute exact path="/dashboard/produtoCultural/cadastro" component={() => <ProdutoCulturalCreate />} />

        </Switch>
    </BrowserRouter>
);

export default Routes;