import React, { Component } from 'react';
import Menu from '../menu';
import api from '../../services/api';
import { BootstrapTable, TableHeaderColumn, InsertButton } from 'react-bootstrap-table';
import './style.css';
import Fab from '@material-ui/core/Fab';
import EditIcon from '@material-ui/icons/Edit';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import ModalUpdate from './modalUpdate';

export default class CategoriasPublicacaoLei extends Component {

  constructor(props) {
    super(props);
    this.loadCategoriasPublicacaoLei = this.loadCategoriasPublicacaoLei.bind(this);
  }

  state = {
    categoriasPublicacaoLei: '',
    show: false,
    categoriasPublicacaoLeiById: ''
  };

  componentDidMount() {
    this.loadCategoriasPublicacaoLei();
  }

  openModal() {
    this.setState({ show: true })
  }

  async loadCategoriasPublicacaoLei() {
    await api.get("/categoriasPublicacaoLei")
      .then(response => {
        this.setState({
          categoriasPublicacaoLei: response.data
        })
      })
      .catch(err => {
        console.log(err);
      });
  };

  handleInsertButtonClick = (onClick) => {

    return (window.location = "categoriasPublicacaoLei/cadastro")
  }

  createCustomInsertButton = (onClick) => {
    return (
      <InsertButton
        btnText='Novo registro'
        btnContextual='btn-primary'
        className='my-custom-class'
        btnGlyphicon='glyphicon-edit'
        onClick={() => this.handleInsertButtonClick(onClick)} />
    );
  }

  render() {
    const btnActions = (cell, row) => {

      const handleUpdate = async userId => {

        const requestOptions = {
          method: 'GET'
        };

        await api.get("/categoriasPublicacaoLei/" + cell, requestOptions)
          .then(response => this.setState({ categoriasPublicacaoLeiById: response.data.categoriasPublicacaoLei }))
          .catch(error => { console.log(error) });
        this.setState({ show: true })
        // setTimeout(handleClick, 2000)
      }



      const handleClick = cell => {

        const requestOptions = {
          method: 'DELETE'
        };

        api.delete("/categoriasPublicacaoLei/" + cell, requestOptions)
          .then(response => { return response.json(); })
          .catch(error => { console.log(error) });

        return (document.location.reload(true))
        // setTimeout(handleClick, 2000)
      }

      return (
        <div>
          <Fab className="btn-editar" onClick={() => handleUpdate(cell)} size="small" aria-label="add">
            <EditIcon />
          </Fab>
          <Fab className="btn-excluir" onClick={() => handleClick(cell)} size="small" aria-label="add">
            <DeleteForeverIcon />
          </Fab>
        </div>
      )
    }

    const options = {
      exportCSVText: 'Exportar em Excel',
      insertBtn: this.createCustomInsertButton,
      sizePerPageList: [{
        text: '5', value: 5
      }, {
        text: '10', value: 10
      }, {
        text: 'All', value: 50
      }]
    };
    const defaultSorted = [{
      dataField: 'name',
      order: 'desc'
    }];
    return (
      <div>
        <Menu />

        <div className="produto-lista" >
          <BootstrapTable
            data={this.state.categoriasPublicacaoLei.categoriasPublicacaoLei}
            striped
            hover
            options={options}
            insertRow
            condensed
            pagination
            keyField="name"
            search
            searchPlaceholder="Procurar..."
            sort="true"
            exportCSV
            defaultSorted={defaultSorted}
            columns={this.state.categoriasPublicacaoLei}
          >
            <TableHeaderColumn width='25%' keyField="nome" dataField='nome'>Nome</TableHeaderColumn>
            <TableHeaderColumn width='15%' dataField='id' dataFormat={btnActions}></TableHeaderColumn>
          </BootstrapTable>

          <ModalUpdate
            show={this.state.show}
            host={'categoriasPublicacaoLei/'}
            onHide={() => this.setState({ show: false })}
            id={this.state.categoriasPublicacaoLeiById.id}
            type={'categoriasPublicacaoLei'}
            categoria={this.state.categoriasPublicacaoLeiById.nome}
          />
        </div>
      </div>
    )
  }
}