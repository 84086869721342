import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import TextField from '@material-ui/core/TextField';
import React, { Component } from 'react';
import { cpfMask } from './mask';
import './style.css';
const { cpf } = require('cpf-cnpj-validator');
const $ = require('jquery');

class IniciarInscricao extends Component {

  constructor(props) {
    super(props);
    this.handleChangeTipo = this.handleChangeTipo.bind(this);
    this.handlechangeMask = this.handlechangeMask.bind(this)
    this.state = {
      documentId: '',
      cpf: '',
      tipoInscricao: ''
    };

  }

  buscaCpf = event => {
    var id = event.target.value;
    let validCPF = cpf.isValid(id)
    if (validCPF === false) {
      alert("Verifique o CPF digitado, ele está inválido")
      this.setState({ cpf: '' })
      $("#cpf").css({
        "background-color": "#eb9e9e",
        color: "black"
      });
      setTimeout(() => {
        $("#resposta").html('CPF INVALIDO');
      }, 1 * 1000)
    } else {
      $("#retorno").removeClass("alert-danger");
      $("#retorno").addClass("alert-success");
      $("#resposta").html('CPF VÁLIDO');
      $("#cpf").css({
        "background-color": "#98FB98",
        "border-color": "#eb9e9e",
        color: "black"
      });
    }
  }

  handlechangeMask(e) {
    this.setState({ cpf: cpfMask(e.target.value) })
  }

  handleChangeTipo(e) {
    this.setState({ tipoInscricao: e.target.value });
  }
  render() {

    const { cpf, tipoInscricao } = this.props

    return (
      <div>

        <div className="form">
          <TextField
            required
            id="cpf"
            name="cpf"
            className="cpf"
            label="CPF"
            placeholder="Digite o CPF sem os pontos"
            fullWidth
            value={cpfMask(cpf)}
            onChange={e => this.props.cpfMask(e.target.value)}
            margin="normal"
            InputLabelProps={{
              shrink: true,
            }}
            onBlur={this.buscaCpf}
            variant="outlined"
          />

          <FormControl required component="fieldset" style={{ marginTop: '30px' }}>
            <FormLabel component="legend">Tipo de inscrição</FormLabel>
            <RadioGroup aria-label="gender" name="gender1" onChange={e => this.props.tipoInscricaoChanged(e.target.value)} value={tipoInscricao}>
              <FormControlLabel value="mni" control={<Radio required={true} />} label="Mostra Nacional - Artista Individual" />
              <FormControlLabel value="mng" control={<Radio required={true} />} label="Mostra Nacional - Grupo de Artistas" />
              <FormControlLabel value="fai" control={<Radio required={true} />} label="Fomento à Produção Anapolina - Artista Individual" />
              <FormControlLabel value="fag" control={<Radio required={true} />} label="Fomento à Produção Anapolina - Grupo de Artistas" />
            </RadioGroup>
          </FormControl>
        </div>
      </div >

    )
  }
}

export default IniciarInscricao;