import React from 'react';
import api, { apiURL } from '../../services/api';
import Menu from '../menu'
import './style.css'
import { CircularProgressbar } from "react-circular-progressbar";
import { MdCheckCircle, MdError } from "react-icons/md";

export default function FormIdentificacao() {
  const [file, setFile] = React.useState({})
  const [checkProgress, setCheckProgress] = React.useState('')
  const [progress, setProgess] = React.useState(0); // progess bar
  const [dataFile, getFile] = React.useState({ name: "", path: "" });
  const el = React.useRef(); // accesing input element

  //CARREGAR ARQUIVO
  const handleChangeFile = (e) => {
    setProgess(0)
    const file = e.target.files[0]; // accesing file
    setFile(file); // storing file
  }

  //UPLOAD ARQUIVO NO BANCO
  async function uploadFile() {
    const formData = new FormData();
    formData.append('file', file)

    const myHeaders = {
      'Content-Type': 'multipart/form-data'
    }

    await api.post("/calendario", formData, {
      onUploadProgress: (ProgressEvent) => {
        let progress = Math.round(
          ProgressEvent.loaded / ProgressEvent.total * 100);
        setProgess(progress)
      },
      headers: myHeaders
    }).then(res => {
      getFile({
        name: res.data.name,
        path: apiURL + res.data.path
      },
        setCheckProgress({ checkProgress: true })
      )
    }).catch(err => setCheckProgress({ checkProgress: false }))
  }

  return (
    <div>
      <Menu />
      <div className="container-slide-index" enctype="multipart/form-data">
        <p className="titulo">Cadastro de Calendário</p>
        <hr />

        <div className="container-slide-index">
          <input className="inputFile" type="file" ref={el} onChange={handleChangeFile} />

          {checkProgress.checkProgress === true &&
            checkProgress.checkProgress === false && (
              <CircularProgressbar
                styles={{
                  root: { width: 24 },
                  path: { stroke: "#7159c1" }
                }}
                strokeWidth={10}
                value={progress}
              />
            )}

          {(checkProgress.checkProgress === true) && <MdCheckCircle size={24} color="#78e5d5" />}
          {checkProgress.checkProgress === false && <MdError size={24} color="#e57878" />}
          <br></br>
          <button onClick={uploadFile} className="upbutton">Upload</button>
          <hr />
          {/* displaying received image*/}
          {dataFile.path && <img src={dataFile.path} alt={dataFile.name} />}
        </div>
      </div>
    </div>
  );
}
