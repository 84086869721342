import React, { Component } from 'react';
import Header from './components/header/index';
import NavBar from './components/navbar';
import Banner from './components/header/banner';
import Footer from './components/footer/index';
import Slide from './components/slide/slide';
import './App.css';

class App extends Component {
  render() {
    document.title = 'Mostra de Teatro - Sec. Cultura'
    return (

      <div>
        <Banner />
        <NavBar />
        <Slide />
        <header><Header /></header>
        <Footer />

      </div>
    )
  }
}


export default App;

