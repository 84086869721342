import React, { Component } from 'react';
import Carousel from 'react-bootstrap/Carousel';
import './style.css';
import slide1 from './slide1.jpg'
import slide2 from './slide2.jpg'
import slide3 from './slide3.jpg'
import slide4 from './slide4.jpg'

export default class Slide extends Component {
  render() {
    return <div className="banner" >
      <Carousel className="carousel" interval={2000} activeIndex={this.index} direction={this.direction} onSelect={this.handleSelect}>
        <Carousel.Item>
          <img
            className="image"
            src={slide1}
            alt="First slide"
          />
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="image"
            src={slide2}
            alt="Second slide"
          />
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="image"
            src={slide3}
            alt="Third slide"
          />
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="image"
            src={slide4}
            alt="Third slide"
          />
        </Carousel.Item>
      </Carousel>
    </div >;
  }
}