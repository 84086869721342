import React from 'react';
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import CustomSnackbar from '../components/Snackbar'
import api, { apiURL } from '../../services/api'
import { CircularProgressbar } from "react-circular-progressbar";
import { MdCheckCircle, MdError } from "react-icons/md";

const inputParsers = {
  date(input) {
    const [month, day, year] = input.split("/");
    return `${year}-${month}-${day}`;
  },
  uppercase(input) {
    return input.toUpperCase();
  },
  number(input) {
    return parseFloat(input);
  }
};

export default function ModalUpdate(props) {
  const [file, setFile] = React.useState({})
  const [checkProgress, setCheckProgress] = React.useState('')
  const [progress, setProgess] = React.useState(0); // progess bar
  const [dataFile, getFile] = React.useState({ name: "", path: "" });
  const el = React.useRef(); // accesing input element

  //ATUALIZAR NO BANCO
  async function handleSubmit(event) {

    event.preventDefault();
    const form = event.target;
    const data = new FormData(form);
    for (let name of data.keys()) {
      const input = form.elements[name];
      const parserName = input.dataset.parse;
      if (parserName) {
        const parser = inputParsers[parserName];
        const parsedValue = parser(data.get(name));
        data.set(name, parsedValue);

      }
    }
    function stringifyFormData(fd) {
      const data = {};
      for (let key of fd.keys()) {
        data[key] = fd.get(key);
      }
      return JSON.stringify(data, null, 2);
    }

    const myHeaders = {
      'Content-Type': 'application/json'
    }

    const raw = stringifyFormData(data);

    const body = raw;
    await api.put(props.host + props.id, body, {
      headers: myHeaders
    })
      .catch(error => { console.log(error) })
    return (document.location.reload(true))
  }

  //CARREGAR ARQUIVO
  const handleChangeFile = (e) => {
    setProgess(0)
    const file = e.target.files[0]; // accesing file
    setFile(file); // storing file
  }

  //ALTERAR ARQUIVO NO BANCO
  async function uploadFile() {
    const formData = new FormData();
    formData.append('file', file)

    const myHeaders = {
      'Content-Type': 'multipart/form-data'
    }

    await api.put(props.hostImages + props.id, formData, {
      onUploadProgress: (ProgressEvent) => {
        let progress = Math.round(
          ProgressEvent.loaded / ProgressEvent.total * 100);
        setProgess(progress)
      },
      headers: myHeaders
    }).then(res => {
      getFile({
        name: res.data.name,
        path: apiURL + res.data.path
      },
        setCheckProgress({ checkProgress: true })
      )
    }).catch(err => setCheckProgress({ checkProgress: false }))
    // return (document.location.reload(true))
  }


  const [values, setValues] = React.useState({
    title: props.title,
    autor: props.autor,
    resumo: props.resumo,
    categoria: props.categoria,
    url: props.url
  });

  const handleChange = name => event => {
    setValues({
      ...values,
      [name]: event.target.value,
    });
  };
  return (
    <>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Alteração</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form className="container-slide-index" enctype="multipart/form-data" onSubmit={handleSubmit}>
            {props.type === 'lives' &&
              <div>
                <strong>Título</strong>
                <textarea className="input-modal" value={values.title} name="title" onChange={handleChange('title')}></textarea>
                <br /><strong>URL</strong>
                <input className="input-modal" value={values.url} name="url" onChange={handleChange('url')} />
                <br /><Button type='submit'>Atualizar </Button>
              </div>
            }

            {
              props.type === 'publicacaoLei' &&
              <form enctype="multipart/form-data" onSubmit={handleSubmit}>
                <div>
                  <strong>Título</strong>
                  <select
                    onChange={handleChange('categoria')}
                    name="categoria"
                    value={values.categoria}
                  >
                    <option value="">Selecionar...</option>
                    <option value="Decretos">Decretos</option>
                    <option value="Regulamentações">Regulamentações</option>
                    <option value="Atas">Atas</option>
                    <option value="Retificações">Retificações</option>
                    <option value="Anexos">Anexos</option>
                  </select><br></br>
                  <textarea className="input-modal" value={values.title} name="title" onChange={handleChange('title')}>{props.title}</textarea>
                  <br />
                  {!props.title && <span>Título não pode ficar em branco</span>}
                  <br />{(props.title) && <Button type='submit'>Atualizar </Button>}
                </div>
              </form>
            }

            {
              props.type === 'categoriasPublicacaoLei' &&
              <form enctype="multipart/form-data" onSubmit={handleSubmit}>
                <div>
                  <strong>Nome</strong>
                  <textarea className="input-modal" value={values.nome} name="nome" onChange={handleChange('nome')}>{props.categoria}</textarea>
                  <br />
                  {!props.categoria && <span>Nome não pode ficar em branco</span>}
                  <br />{(props.categoria) && <Button type='submit'>Atualizar </Button>}
                </div>
              </form>
            }

            {
              props.type === 'produtoCultural' &&
              <form enctype="multipart/form-data" onSubmit={handleSubmit}>
                <div>
                  <strong>Categoria </strong>
                  <select
                    onChange={handleChange('categoria')}
                    name="categoria"
                    value={values.categoria || props.categoria}
                  >
                    <option value="Artes Visuais">Artes Visuais</option>
                    <option value="Audiovisual">Audiovisual</option>
                    <option value="Cultura de rua">Cultura de Rua / Afro-Brasileira</option>
                    <option value="Cultura Popular Artesanato">Cultura Popular e Artesanato</option>
                    <option value="Cultura Popular Artesanato - Videos">Cultura Popular e Artesanato - Vídeos</option>
                    <option value="Dança">Dança</option>
                    <option value="Literatura Escrita">Literatura - Categoria Escrita</option>
                    <option value="Literatura Falada">Literatura - Categoria Falada</option>
                    <option value="Teatro">Teatro e circo</option>
                    <option value="Economia">Economia Criativa e Cultura digital</option>
                    <option value="Musica">Música / Podcast</option>
                  </select><br></br><br></br>
                  <strong>Título </strong>
                  <textarea className="input-modal" value={values.title} name="title" onChange={handleChange('title')}>{props.title}</textarea>
                  <br />
                  <strong>Autor </strong>
                  <textarea className="input-modal" value={values.autor} name="autor" onChange={handleChange('autor')}>{props.autor}</textarea>
                  {!props.title && <span>Título não pode ficar em branco</span>}
                  <br />
                  {(values.categoria === 'Artes Visuais' || values.categoria === 'Cultura Popular Artesanato' || values.categoria === 'Literatura Escrita') &&
                    <strong>Arquivo </strong>
                  }
                  {(values.categoria === 'Artes Visuais' || values.categoria === 'Cultura Popular Artesanato' || values.categoria === 'Literatura Escrita') &&
                    <form className="container-slide-index" enctype="multipart/form-data">

                      {(props.ext === 'img' && props.type !== 'lives') ? <img className="img-modal" src={props.url} alt='imagem' /> : null}
                      {(props.ext === 'doc') ? <a href={props.url} target='blank'>Clique para visualizar o arquivo</a> : null}
                      {props.type !== 'lives' && <input type="file" ref={el} onChange={handleChangeFile} />}
                      {checkProgress.checkProgress === true &&
                        checkProgress.checkProgress === false && (
                          <CircularProgressbar
                            styles={{
                              root: { width: 24 },
                              path: { stroke: "#7159c1" }
                            }}
                            strokeWidth={10}
                            value={progress}
                          />
                        )}

                      {(checkProgress.checkProgress === true) && <MdCheckCircle size={24} color="#78e5d5" />}
                      {checkProgress.checkProgress === false && <MdError size={24} color="#e57878" />}
                      <br></br>
                      <br></br>
                      {props.type !== 'lives' && props.type !== 'publicacaoLei' && <button onClick={uploadFile} className="btn-image-update" type="button">{(props.ext === 'img') ? 'Atualizar Imagem' : 'Atualizar Arquivo'} </button>}

                    </form>
                  }
                  {(
                    values.categoria === 'Audiovisual' ||
                    values.categoria === 'Cultura de rua' ||
                    values.categoria === 'Cultura Popular Artesanato Videos' ||
                    values.categoria === 'Dança' ||
                    values.categoria === 'Literatura Falada' ||
                    values.categoria === 'Teatro' ||
                    values.categoria === 'Economia' ||
                    values.categoria === 'Musica') &&
                    <strong>URL</strong>
                  }
                  {(
                    values.categoria === 'Audiovisual' ||
                    values.categoria === 'Cultura de rua' ||
                    values.categoria === 'Cultura Popular Artesanato Videos' ||
                    values.categoria === 'Dança' ||
                    values.categoria === 'Literatura Falada' ||
                    values.categoria === 'Teatro' ||
                    values.categoria === 'Economia' ||
                    values.categoria === 'Musica') &&
                    <textarea className="input-modal" value={values.url} name="url" onChange={handleChange('url')}>{props.url}</textarea>
                  }
                  <br />{(props.title) && <Button type='submit'>Atualizar </Button>}
                </div>

              </form>
            }

            {props.type !== 'produtoCultural' && (props.title && props.type !== 'lives' && props.type !== 'publicacaoLei') ? <textarea className="input-modal" value={values.title} name="title" onChange={handleChange('title')}>{props.title}</textarea> : null}
            {props.type !== 'produtoCultural' && (props.title && props.type !== 'lives' && props.type !== 'publicacaoLei') ? <textarea className="input-modal" value={values.resumo} name="resumo" onChange={handleChange('resumo')}>{props.resumo}</textarea> : null}
            {props.type !== 'categorias' || props.type !== 'produtoCultural' &&
              <form className="container-slide-index" enctype="multipart/form-data" onChange={uploadFile}>

                {(props.ext === 'img' && props.type !== 'lives') ? <img className="img-modal" src={props.url} alt='imagem' /> : null}
                {(props.ext === 'doc') ? <a href={props.url} target='blank'>Clique para visualizar o arquivo</a> : null}
                {props.type !== 'lives' && <input type="file" ref={el} onChange={handleChangeFile} />}
                {checkProgress.checkProgress === true &&
                  checkProgress.checkProgress === false && (
                    <CircularProgressbar
                      styles={{
                        root: { width: 24 },
                        path: { stroke: "#7159c1" }
                      }}
                      strokeWidth={10}
                      value={progress}
                    />
                  )}

                {(checkProgress.checkProgress === true) && <MdCheckCircle size={24} color="#78e5d5" />}
                {checkProgress.checkProgress === false && <MdError size={24} color="#e57878" />}
                <br></br>
                <br></br>
                {props.type !== 'lives' && props.type !== 'publicacaoLei' && <button className="btn-image-update" type='submit'>{(props.ext === 'img') ? 'Atualizar Imagem' : 'Atualizar Arquivo'} </button>}

              </form>}
          </form>
        </Modal.Body>
        {/* <Modal.Footer>
          <Button onClick={props.onHide}>Close</Button>
        </Modal.Footer> */}
      </Modal>
    </>
  );
}
