import React, { Component } from 'react'
import api from '../../services/api'
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles(theme => ({
  card: {
    maxWidth: 345,
    margin: theme.spacing(2),
  },
  media: {
    height: 190,
  },
}));

export default class Teatro extends Component {
  state = {
    produtoCultural: [],
    arrayMap: false,
  }

  async componentDidMount() {
    await api.get("/api/v1/culturalProduct/category?category=Teatro")
      .then(response => {
        this.setState({ produtoCultural: response.data.culturalProduct, arrayMap: true })
      })
      .catch(err => {
        console.log(err);
      });
  }

  render() {
    const { produtoCultural, arrayMap } = this.state

    function Media(props) {
      const classes = useStyles();
      return (produtoCultural ? produtoCultural : []).map(function (item, i) {

        var query = item.url;
        var partes = query.split('v');
        var partes = query.split('&');
        var data = {};

        var chaveValor = partes[0].split('=');
        var chave = chaveValor[0];
        var valor = chaveValor[1];
        data[chave] = valor;

        return (
          <Card key={item.id} className={classes.card}>
            <CardHeader
              title={item.author}
            />
            <CardHeader
              title={item.title}
            />
            <div className='img-portifolio'>
              {
                item.url &&
                <iframe
                  width='100%'
                  height="315"
                  src={`https://www.youtube.com/embed/${valor}`}
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen>
                </iframe>
              }
            </div>
          </Card>
        );
      });
    }

    return (
      <div style={{ placeContent: 'center' }} className="row">
        <Media />
      </div>
    )
  }
}