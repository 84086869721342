import React, { Component } from 'react';
import Sidebar from './sidebar'
import Footer from './footer/index';
import ProdutoCultural from './containers/ProdutoCultural'
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import Cadastro from './containers/Cadastro'
import TextoIndex from './containers/TextoIndex'
import Titulo from './components/Textos/titulo'
import './app.css';
import api from './services/api'
import List from '@material-ui/core/List';
import Collapse from 'react-bootstrap/Collapse'
import Button from 'react-bootstrap/Button'

export default class App extends Component {
  state = {
    produtoCultural: false,
    lei: false,
    leiMb: false,
    publicacaoLei: false,
    publicacaoLeiDados: [],
    cadastro: false,
    arrayMap: false,
  }

  async componentDidMount() {
    await api.get("/api/v1/publication")
      .then(response => {
        this.setState({ publicacaoLeiDados: response.data.publication, arrayMap: true })
      })
      .catch(err => {
        console.log(err);
      });
  }

  render() {
    const { publicacaoLeiDados, arrayMap, produtoCultural, publicacaoLei, cadastro, lei } = this.state;
    const ListItemLink = (props) => {
      return <ListItem button component="a" {...props} />;
    }

    const linkPublication = `https://api.anapolis.go.gov.br/apiCultura/publications/`

    const removeAcento = (string) => {
      const final = string.normalize('NFD').replace(/[\u0300-\u036f]/g, "").toLowerCase()
      return final
    }

    return (
      <div>
        <Sidebar />
        <TextoIndex />

        <div className='Menu-Collapse' style={{ width: '80%', margin: '0 auto' }}>

          <Button
            onClick={() => this.setState({ lei: !lei })}
            aria-controls="example-collapse-text"
            aria-expanded={lei}
          >
            A Lei Aldir Blanc
          </Button>
          <Collapse in={lei}>
            <div id="example-collapse-text">
              <div className='embed'>
                <a style={{ marginBottom: '20px !important' }} href='/Lei_Aldir_Blanc_DOU.pdf' target='_blanc'>Clique aqui para o download</a>

              </div>
            </div>
          </Collapse>
        </div>

        <div className='Menu-Collapse' style={{ width: '80%', margin: '0 auto' }}>

          <Button
            onClick={() => this.setState({ publicacaoLei: !publicacaoLei })}
            aria-controls="example-collapse-text"
            aria-expanded={publicacaoLei}
          >
            Publicações Oficiais
          </Button>
          <Collapse in={publicacaoLei}>
            <div id="example-collapse-text">

              <div>
                <h4>Decretos</h4>
                <List component="nav" aria-label="main mailbox folders">

                  {(arrayMap == false ? [] : publicacaoLeiDados).map((step, index) =>
                    step.category === 'Decretos' &&
                    <ListItemLink href={linkPublication + removeAcento(step.category) + '/' + step.file} target="_blank">

                      {(publicacaoLeiDados[index].file.substring(publicacaoLeiDados[index].file.indexOf('.')) === '.pdf') ?
                        <PictureAsPdfIcon style={{ color: 'red' }} />
                        :
                        <PictureAsPdfIcon style={{ color: 'blue' }} />
                      }

                      &nbsp;<ListItemText primary={step.title} />
                    </ListItemLink>

                  )
                  }
                </List>
                <h4>Regulamentações</h4>
                <List component="nav" aria-label="main mailbox folders">

                  {(arrayMap == false ? [] : publicacaoLeiDados).map((step, index) =>
                    step.category === 'Regulamentações' &&
                    <ListItemLink href={linkPublication + removeAcento(step.category) + '/' + step.file} target="_blank">

                      {(publicacaoLeiDados[index].file.substring(publicacaoLeiDados[index].file.indexOf('.')) === '.pdf') ?
                        <PictureAsPdfIcon style={{ color: 'red' }} />
                        :
                        <PictureAsPdfIcon style={{ color: 'blue' }} />
                      }

                      &nbsp;<ListItemText primary={step.title} />
                    </ListItemLink>

                  )
                  }
                </List>
                <h4>Atas</h4>
                <List component="nav" aria-label="main mailbox folders">

                  {(arrayMap == false ? [] : publicacaoLeiDados).map((step, index) =>
                    step.category === 'Atas' &&
                    <ListItemLink href={linkPublication + removeAcento(step.category) + '/' + step.file} target="_blank">

                      {(publicacaoLeiDados[index].file.substring(publicacaoLeiDados[index].file.indexOf('.')) === '.pdf') ?
                        <PictureAsPdfIcon style={{ color: 'red' }} />
                        :
                        <PictureAsPdfIcon style={{ color: 'blue' }} />
                      }

                      &nbsp;<ListItemText primary={step.title} />
                    </ListItemLink>

                  )
                  }
                </List>
                <h4>Retificações</h4>
                <List component="nav" aria-label="main mailbox folders">

                  {(arrayMap == false ? [] : publicacaoLeiDados).map((step, index) =>
                    step.category === 'Retificações' &&
                    <ListItemLink href={linkPublication + removeAcento(step.category) + '/' + step.file} target="_blank">

                      {(publicacaoLeiDados[index].file.substring(publicacaoLeiDados[index].file.indexOf('.')) === '.pdf') ?
                        <PictureAsPdfIcon style={{ color: 'red' }} />
                        :
                        <PictureAsPdfIcon style={{ color: 'blue' }} />
                      }

                      &nbsp;<ListItemText primary={step.title} />
                    </ListItemLink>

                  )
                  }
                </List>
                <h4>Anexos</h4>
                <List component="nav" aria-label="main mailbox folders">

                  {(arrayMap == false ? [] : publicacaoLeiDados).map((step, index) =>
                    step.category === 'Anexos' &&
                    <ListItemLink href={linkPublication + removeAcento(step.category) + '/' + step.file} target="_blank">

                      {(publicacaoLeiDados[index].file.substring(publicacaoLeiDados[index].file.indexOf('.')) === '.pdf') ?
                        <PictureAsPdfIcon style={{ color: 'red' }} />
                        :
                        <PictureAsPdfIcon style={{ color: 'blue' }} />
                      }

                      &nbsp;<ListItemText primary={step.title} />
                    </ListItemLink>

                  )
                  }
                </List>

              </div>
            </div>
          </Collapse>
        </div>

        <div className='Menu-Collapse' style={{ width: '80%', margin: '0 auto' }}>

          <Button
            onClick={() => this.setState({ produtoCultural: !produtoCultural })}
            aria-controls="example-collapse-text"
            aria-expanded={produtoCultural}
          >
            Produtos culturais
          </Button>
          <Collapse in={produtoCultural}>
            <div id="example-collapse-text">
              <ProdutoCultural />
            </div>
          </Collapse>
        </div>

        <div className='Menu-Collapse' style={{ width: '80%', margin: '0 auto' }}>

          <Button
            onClick={() => this.setState({ cadastro: !cadastro })}
            aria-controls="example-collapse-text"
            aria-expanded={cadastro}
          >
            Cadastro
          </Button>
          <Collapse in={cadastro}>
            <div id="example-collapse-text">
              <Cadastro />
            </div>
          </Collapse>
        </div>
        <Footer />
      </div >
    )
  }
}