import React, { Component } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import api from '../services/api'
import Sidebar from '../sidebar'
import Titulo from '../components/Textos/titulo'

export default class PublicacoesOficiais extends Component {
  state = {
    arrayMap: false,
    tutorialSteps: {
      url: '#',
      nome: 'Arquivo 1',
      nome_file: 'arquivo_teste_1.pdf'
    }
  }

  render() {

    const useStyles = makeStyles((theme) => ({
      root: {
        width: '100%',
        backgroundColor: theme.palette.background.paper,
      },
    }));

    const ListItemLink = (props) => {
      return <ListItem button component="a" {...props} />;
    }
    return (
      <div className={useStyles.root}>
        <Sidebar />
        <br />
        <div style={{ width: '80%', margin: '0 auto' }}>
          <Titulo titulo={'Publicações Oficiais'} />
          {/* <List component="nav" aria-label="main mailbox folders">

            <ListItemLink href={'/Files/Regulamento-Inciso-II.pdf'} target="_blank">
              <PictureAsPdfIcon style={{ color: 'red' }} />
              &nbsp;<ListItemText primary={'Regulamento Inciso II (DOM 23/10/20)'} />
            </ListItemLink>
            <ListItemLink href={'/Files/Regulamento-Inciso-III.pdf'} target="_blank">
              <PictureAsPdfIcon style={{ color: 'red' }} />
              &nbsp;<ListItemText primary={'Regulamento Inciso III (DOM 23/10/20)'} />
            </ListItemLink>
            <ListItemLink href={'/Files/Anexos-Inciso-III.doc'} target="_blank">
              <PictureAsPdfIcon style={{ color: 'blue' }} />
             &nbsp; <ListItemText primary={'Anexos Inciso III'} />
            </ListItemLink>

          </List> */}
        </div>
      </div>
    );
  }
}