import React, { Component } from 'react'

export default class ContatoLandingPage extends Component {
  render() {
    return (
      <div className="footer">
        <div className="contato">
          <strong>Secretaria Municipal de Cultura<br />
          Praça Bom Jesus,<br /> Centro</strong>
          <br /><hr />
          Para mais informações:<br></br>
          (62) 3902-1074 / 1111  <br></br>
          cultura@anapolis.go.gov.br<br></br><br></br>
        </div>
      </div>
    )
  }
}