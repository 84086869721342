import React from 'react'

const InputLogin = ({ placeHolder, type, label, value, onChange, error }) => (
  <div className='Input-Login flex vertical'>
    {label && (<label>{label}</label>)}
    {error && (<small className='small-danger'>{error}</small>)}
    <input
      placeholder={placeHolder ? placeHolder : ''}
      type={type}
      value={value}
      onChange={onChange}
      className={`input-login ${error ? 'input-error' : ''}`} />
  </div>
)

export default InputLogin