import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import InsertDriveFile from '@material-ui/icons/InsertDriveFile';
import PdfRegulamento from './regulamento_retificado_2020_04_22.pdf';
import DocCurriculo from './FichadeInscricaoCurriculo.doc';
import DocIdentificacao from './FichadeInscricaoIdentificacao.doc';
import DocProjeto from './FichadeInscricaoProjeto.doc';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: 460,
    backgroundColor: theme.palette.background.paper,
  },
}));

function ListItemLink(props) {
  return <ListItem button component="a" {...props} />;
}

export default function SimpleList() {
  const classes = useStyles();


  return (
    <div className={classes.root}>
      <List component="nav" aria-label="main mailbox folders">
        <ListItemLink href={PdfRegulamento} target="_blank">
          <PictureAsPdfIcon style={{ color: 'red' }} />
          <ListItemText primary=" Regulamento - itens 2.1.2 e 5.8 retificados" />
        </ListItemLink>

        <ListItemLink href={DocCurriculo} target="_blank">
          <InsertDriveFile style={{ color: 'blue' }} />
          <ListItemText primary=" Template Ficha de Inscrição - Currículo" />
        </ListItemLink>

        <ListItemLink href={DocIdentificacao} target="_blank">
          <InsertDriveFile style={{ color: 'blue' }} />
          <ListItemText primary=" Template de Ficha de Inscrição - Identificação" />
        </ListItemLink>

        <ListItemLink href={DocProjeto} target="_blank">
          <InsertDriveFile style={{ color: 'blue' }} />
          <ListItemText primary=" Template Ficha de Inscrição - Projeto" />
        </ListItemLink>

      </List>
    </div>
  );
}