import React, { Component } from 'react';
import Menu from '../menu';
import api from '../../services/api';
import { BootstrapTable, TableHeaderColumn, InsertButton } from 'react-bootstrap-table';
import './style.css';
import Fab from '@material-ui/core/Fab';
import EditIcon from '@material-ui/icons/Edit';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import ModalUpdate from './modalUpdate';

export default class Calendario extends Component {

  constructor(props) {
    super(props);
    this.loadCalendario = this.loadCalendario.bind(this);
  }

  state = {
    calendario: '',
    show: false,
    calendarioById: ''
  };

  componentDidMount() {
    this.loadCalendario();
  }

  openModal() {
    this.setState({ show: true })
  }

  async loadCalendario() {
    await api.get("/calendario")
      .then(response => {
        this.setState({
          calendario: response.data
        })
      })
      .catch(err => {
        console.log(err);
      });
  };

  handleInsertButtonClick = (onClick) => {

    return (window.location = "calendario/cadastro")
  }

  createCustomInsertButton = (onClick) => {
    return (
      <InsertButton
        btnText='Novo registro'
        btnContextual='btn-primary'
        className='my-custom-class'
        btnGlyphicon='glyphicon-edit'
        onClick={() => this.handleInsertButtonClick(onClick)} />
    );
  }

  render() {
    const btnActions = (cell, row) => {

      const handleUpdate = async userId => {

        const requestOptions = {
          method: 'GET'
        };

        await api.get("/calendario/" + cell, requestOptions)
          .then(response => this.setState({ calendarioById: response.data.calendario }))
          .catch(error => { console.log(error) });
        this.setState({ show: true })
        // setTimeout(handleClick, 2000)
      }



      const handleClick = cell => {

        const requestOptions = {
          method: 'DELETE'
        };

        api.delete("/calendario/" + cell, requestOptions)
          .then(response => { return response.json(); })
          .catch(error => { console.log(error) });

        return (document.location.reload(true))
        // setTimeout(handleClick, 2000)
      }
      return (
        <div>
          <Fab className="btn-editar" onClick={() => handleUpdate(cell)} size="small" aria-label="add">
            <EditIcon />
          </Fab>
          <Fab className="btn-excluir" onClick={() => handleClick(cell)} size="small" aria-label="add">
            <DeleteForeverIcon />
          </Fab>
        </div>
      )
    }

    const options = {
      exportCSVText: 'Exportar em Excel',
      insertBtn: this.createCustomInsertButton,
      sizePerPageList: [{
        text: '5', value: 5
      }, {
        text: '10', value: 10
      }, {
        text: 'All', value: 50
      }]
    };
    const defaultSorted = [{
      dataField: 'name',
      order: 'desc'
    }];
    return (
      <div>
        <Menu />

        <div className="produto-lista" >
          <BootstrapTable
            data={this.state.calendario.calendarios}
            striped
            hover
            options={options}
            insertRow
            condensed
            pagination
            keyField="name"
            search
            searchPlaceholder="Procurar..."
            sort="true"
            exportCSV
            defaultSorted={defaultSorted}
            columns={this.state.calendario}
          >
            <TableHeaderColumn width='25%' keyField="nome" dataField='nome'>Nome</TableHeaderColumn>
            <TableHeaderColumn width='15%' dataField='size'>Tamanho do arquivo</TableHeaderColumn>
            <TableHeaderColumn width='15%' dataField='updatedAt'>Última atualização</TableHeaderColumn>
            <TableHeaderColumn width='15%' dataField='id' dataFormat={btnActions}></TableHeaderColumn>
          </BootstrapTable>

          <ModalUpdate
            show={this.state.show}
            host={"/calendario/"}
            onHide={() => this.setState({ show: false })}
            id={this.state.calendarioById.id}
            url={this.state.calendarioById.url}
            ext={'img'}
          />
        </div>
      </div>
    )
  }
}