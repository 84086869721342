import React, { Component } from 'react';
import NavBar from '../navbar/index';
import Banner from '../header/banner';
import Titulo from './titulo';
import DownloadsLinks from './downloads';
import Footer from '../footer/index';
import './style.css';
import 'bootstrap/dist/css/bootstrap.min.css';

export default class Downloads extends Component {
  render() {
    document.title = 'Mostra de Teatro - Sec. Cultura'
    return <div>
      <header><Banner /></header>
      <NavBar />
      <div className="public">
        <Titulo />
        <DownloadsLinks />
      </div>
      <Footer />
    </div>
  }
}