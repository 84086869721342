import React from 'react';
import api, { apiURL } from '../../services/api';
import Menu from '../menu'
import './style.css'
import { CircularProgressbar } from "react-circular-progressbar";
import { MdCheckCircle, MdError } from "react-icons/md";

const inputParsers = {
  date(input) {
    const [month, day, year] = input.split("/");
    return `${year}-${month}-${day}`;
  },
  uppercase(input) {
    return input.toUpperCase();
  },
  number(input) {
    return parseFloat(input);
  }
};

export default function FormIdentificacao() {
  const [nome, setNome] = React.useState({})
  const [categoriasPublicacaoLei, setCategoriasPublicacaoLei] = React.useState({})
  const [dataFile, getFile] = React.useState({ name: "", path: "" });
  const el = React.useRef(); // accesing input element

  //CARREGA DADOS DO BANCO PELO ID
  const loadCategoriasPublicacaoLei = (id) => {
    api.get('/categoriasPublicacaoLei/' + id)
      .then(response => {
        setCategoriasPublicacaoLei({ categoriasPublicacaoLei: response.data.categoriasPublicacaoLei })
      })
      .catch(error => { console.log(error) });
  }

  //REGISTRO NO BANCO
  async function handleSubmit(event) {

    event.preventDefault();
    const form = event.target;
    const data = new FormData(form);
    for (let name of data.keys()) {
      const input = form.elements[name];
      const parserName = input.dataset.parse;
      if (parserName) {
        const parser = inputParsers[parserName];
        const parsedValue = parser(data.get(name));
        data.set(name, parsedValue);

      }
    }
    function stringifyFormData(fd) {
      const data = {};
      for (let key of fd.keys()) {
        data[key] = fd.get(key);
      }
      return JSON.stringify(data, null, 2);
    }

    const myHeaders = {
      'Content-Type': 'application/json'
    }

    const raw = stringifyFormData(data);

    const body = raw;
    await api.post("/categoriasPublicacaoLei", body, {
      headers: myHeaders
    })
      .then(response => loadCategoriasPublicacaoLei(response.data.categoriasPublicacaoLei.id))
      .catch(error => { console.log(error) });
    // return document.location.href = '/dashboard/categoriasPublicacaoLei'
  }

  const [values, setValues] = React.useState({
    nome: ''
  });
  const handleChange = name => event => {
    setValues({
      ...values,
      [name]: event.target.value,
    });
  };

  return (
    <div>
      <Menu />
      <form className="container-slide-index" enctype="multipart/form-data" onSubmit={handleSubmit}>
        <p className="titulo">Cadastrar Categoria de Publicações/ Lei Aldir Blanc</p>
        <hr />
        <div className="content-slide-index">
          <div className="input-slide-index">
            <input id="Nome"
              className="textarea-slide-index"
              placeholder="Digite o nome da categoria..."
              name="nome"
              value={values.nome}
              onChange={handleChange('nome')}
              nome="nome"
            >
            </input>
          </div>
        </div>
        <button className="btn-slide-index" type="submit">Cadastrar</button>
      </form>
    </div>
  );
}
