import React, { Component } from "react";
import { uniqueId } from "lodash";
import filesize from "filesize";
import api from "../../../../services/api";
import GlobalStyle from "../../styles/global";
import { Container, Content } from "./styles";
import Upload from "../upload/index";
import FileList from "../fileList/index";
import Button from '@material-ui/core/Button';

class UploadFiles extends Component {
  state = {
    uploadedFiles: [],
  };

  async componentDidMount() {

    const response = await api.get("posts");

    this.setState({
      uploadedFiles: response.data.map(file => ({
        id: file._id,
        name: file.name,
        key: file.key,
        preview: file.url,
        uploaded: true,
        url: file.url,
      }))
    });
  }


  handleUpload = files => {
    const uploadedFiles = files.map(file => ({
      file,
      id: uniqueId(),
      key: file.key,
      name: file.name,
      readableSize: filesize(file.size),
      preview: URL.createObjectURL(file),
      progress: 0,
      uploaded: false,
      error: false,
      url: null,
    }));

    this.setState({
      uploadedFiles: this.state.uploadedFiles.concat(uploadedFiles)
    });

    uploadedFiles.forEach(this.processUpload);

  };

  updateFile = (id, data) => {
    this.setState({
      uploadedFiles: this.state.uploadedFiles.map(uploadedFile => {
        return id === uploadedFile.id
          ? { ...uploadedFile, ...data }
          : uploadedFile;
      })
    });
  };

  processUpload = uploadedFile => {
    const data = new FormData();

    data.append("file", uploadedFile.file, uploadedFile.name);

    api
      .post("posts", data, {
        onUploadProgress: e => {
          const progress = parseInt(Math.round((e.loaded * 100) / e.total));

          this.updateFile(uploadedFile.id, {
            progress
          });
        }
      })
      .then(response => {
        this.updateFile(uploadedFile.id, {
          uploaded: true,
          id: response.data._id,
          url: response.data.url
        });
      })
      .catch(() => {
        this.updateFile(uploadedFile.id, {
          error: true
        });
      });
  };

  handleDelete = async id => {
    await api.delete(`posts/${id}`);

    this.setState({
      uploadedFiles: this.state.uploadedFiles.filter(file => file.id !== id)
    });
  };

  componentWillUnmount() {
    this.state.uploadedFiles.forEach(file => URL.revokeObjectURL(file.preview));
  }



  render() {

    const { uploadedFiles } = this.state;

    async function handleSubmit(event) {
      event.preventDefault();
      const form = event.target;
      const data = new FormData(form);

      function stringifyFormData(fd) {
        const data = {
          '_id': uploadedFiles.id,
          'name': uploadedFiles.name,
          'size': uploadedFiles.readableSize,
          'key': uploadedFiles.key,
          'url:': uploadedFiles.url,
        };
        for (let key of fd.keys()) {
          data[key] = fd.get(key);
        }
        return JSON.stringify(data, null, 2);

      }

      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var raw = stringifyFormData(data);
      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow"
      };

      fetch("http://localhost:3001/posts", requestOptions)
        .then(response => response.text())
        .then(responseText => responseText)
        .catch(error => error);
    }
    return (

      <Container>
        <form className="form" onSubmit={handleSubmit}>
          <Content>
            <Upload uploadedFiles={uploadedFiles} onUpload={this.handleUpload} />
            {!!uploadedFiles.length && (
              <FileList files={uploadedFiles} onDelete={this.handleDelete} />
            )}
          </Content>
          <GlobalStyle />

          <div>
            <Button type="submit" variant="contained" className="botoes" disableElevation>
              Confirmar Inscrição
        </Button>
          </div>

        </form>

      </Container>


    );
  }
}

export default UploadFiles;