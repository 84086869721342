import React, { Component } from 'react'
import './index.css'
import Collapse from 'react-bootstrap/Collapse'
import Button from 'react-bootstrap/Button'

import ArtesVisuais from './ProdutoCultural/artesVisuais'
import AudioVisual from './ProdutoCultural/audioVisual'
import CulturaDeRua from './ProdutoCultural/culturaDeRua'
import CulturaPopularArtesanato from './ProdutoCultural/culturaPopularArtesanato'
import CulturaPopularArtesanatoVideos from './ProdutoCultural/culturaPopularArtesanatoVideos'
import Danca from './ProdutoCultural/danca'
import LiteraturaEscrita from './ProdutoCultural/literaturaEscrita'
import LiteraturaFalada from './ProdutoCultural/literaturaFalada'
import Teatro from './ProdutoCultural/teatro'
import Economia from './ProdutoCultural/economia'
import Musica from './ProdutoCultural/musica'

export default class ProdutoCultural extends Component {
  state = {
    artesVisuais: false,
    audioVisual: false,
    culturaDeRua: false,
    culturaPopularArtesanato: false,
    culturaPopularArtesanatoVideos: false,
    danca: false,
    literaturaEscrita: false,
    literaturaFalada: false,
    teatro: false,
    economia: false,
    musica: false,
  }

  render() {
    const {
      artesVisuais,
      audioVisual,
      culturaDeRua,
      culturaPopularArtesanato,
      culturaPopularArtesanatoVideos,
      danca,
      literaturaEscrita,
      literaturaFalada,
      teatro,
      economia,
      musica,
    } = this.state
    return (
      <div className='Produto-Cultural flex vertical'>

        {/* ARTES VISUAIS */}
        <Button
          onClick={() => this.setState({ artesVisuais: !artesVisuais })}
          aria-controls="example-collapse-text"
          aria-expanded={artesVisuais}
        >
          Artes Visuais
      </Button>
        <Collapse in={artesVisuais}>
          <div id="example-collapse-text">
            <ArtesVisuais />
          </div>
        </Collapse>

        {/* AUDIOVISUAL */}
        <Button
          onClick={() => this.setState({ audioVisual: !audioVisual })}
          aria-controls="example-collapse-text"
          aria-expanded={audioVisual}
        >
          Audiovisual
      </Button>
        <Collapse in={audioVisual}>
          <div id="example-collapse-text">
            <AudioVisual />
          </div>
        </Collapse>

        {/* CULTURA DE RUA */}
        <Button
          onClick={() => this.setState({ culturaDeRua: !culturaDeRua })}
          aria-controls="example-collapse-text"
          aria-expanded={culturaDeRua}
        >
          Cultura de Rua / Afro-Brasileira
      </Button>
        <Collapse in={culturaDeRua}>
          <div id="example-collapse-text">
            <CulturaDeRua />
          </div>
        </Collapse>

        {/* CULTURA POPULAR E ARTESANATO */}
        <Button
          onClick={() => this.setState({ culturaPopularArtesanato: !culturaPopularArtesanato })}
          aria-controls="example-collapse-text"
          aria-expanded={culturaPopularArtesanato}
        >
          Cultura popular e artesanato
      </Button>
        <Collapse in={culturaPopularArtesanato}>
          <div id="example-collapse-text">
            <CulturaPopularArtesanato />
          </div>
        </Collapse>

        {/* CULTURA POPULAR E ARTESANATO - VÍDEOS */}
        <Button
          onClick={() => this.setState({ culturaPopularArtesanatoVideos: !culturaPopularArtesanatoVideos })}
          aria-controls="example-collapse-text"
          aria-expanded={culturaPopularArtesanatoVideos}
        >
          Cultura popular e artesanato - Vídeos
      </Button>
        <Collapse in={culturaPopularArtesanatoVideos}>
          <div id="example-collapse-text">
            <CulturaPopularArtesanatoVideos />
          </div>
        </Collapse>

        {/* Danças */}
        <Button
          onClick={() => this.setState({ danca: !danca })}
          aria-controls="example-collapse-text"
          aria-expanded={danca}
        >
          Dança
      </Button>
        <Collapse in={danca}>
          <div id="example-collapse-text">
            <Danca />
          </div>
        </Collapse>

        {/* LITERATURA  - CATEGORIA ESCRITA */}
        <Button
          onClick={() => this.setState({ literaturaEscrita: !literaturaEscrita })}
          aria-controls="example-collapse-text"
          aria-expanded={literaturaEscrita}
        >
          Literatura - Categoria escrita
      </Button>
        <Collapse in={literaturaEscrita}>
          <div id="example-collapse-text">
            <LiteraturaEscrita />
          </div>
        </Collapse>

        {/* LITERATURA  - CATEGORIA FALADA */}
        <Button
          onClick={() => this.setState({ literaturaFalada: !literaturaFalada })}
          aria-controls="example-collapse-text"
          aria-expanded={literaturaFalada}
        >
          Literatura - Categoria Falada
      </Button>
        <Collapse in={literaturaFalada}>
          <div id="example-collapse-text">
            <LiteraturaFalada />
          </div>
        </Collapse>

        {/* TEATRO E CIRCO */}
        <Button
          onClick={() => this.setState({ teatro: !teatro })}
          aria-controls="example-collapse-text"
          aria-expanded={teatro}
        >
          Teatro e circo
      </Button>
        <Collapse in={teatro}>
          <div id="example-collapse-text">
            <Teatro />
          </div>
        </Collapse>

        {/* ECONOMIA CRIATIVA E CULTURA DIGITAL */}
        <Button
          onClick={() => this.setState({ economia: !economia })}
          aria-controls="example-collapse-text"
          aria-expanded={economia}
        >
          Economia criativa e cultura digital
      </Button>
        <Collapse in={economia}>
          <div id="example-collapse-text">
            <Economia />
          </div>
        </Collapse>

        {/* MÚSICA / PODCAST */}
        <Button
          onClick={() => this.setState({ musica: !musica })}
          aria-controls="example-collapse-text"
          aria-expanded={musica}
        >
          Música / Podcast
      </Button>
        <Collapse in={musica}>
          <div id="example-collapse-text">
            <Musica />
          </div>
        </Collapse>
      </div>
    )
  }
}