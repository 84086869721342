import React, { Component } from 'react';
import UploadImagem from './upload';
import 'bootstrap/dist/css/bootstrap.min.css';

export default class UploadImagens extends Component {
  render() {
    return <div>
      <UploadImagem />
    </div>
  }
}