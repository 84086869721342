import React, { Component } from 'react';
import Button from '../../components/Button/Simples';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import Select from '../../components/Inputs/Select'
import InputSimples from '../../components/Inputs/Simples'
import Alerta from '../../components/Alert/Danger'
import errorHandling from '../../actions/errorHandling'
import api, { apiURL } from '../../services/api';
import { uniqueId } from "lodash";
import filesize from "filesize";
import { Container, Content, Paragraph } from "./styles";
import Upload from "./onUpload";
import FileList from '../../components/fileList'
import axios from 'axios';
import { Redirect } from 'react-router'
import '../index.css'
import Sidebar from '../../sidebar'

class CadastroCultura extends Component {

  state = {
    open: false,
    idInscricao: '',
    cpf: '',
    nome_representante: '',
    cpfEncoded: '',
    redirect: false,
    inciso: false,
    incisoValue: '',
    modalidade: false,
    modalidadeValue: '',
    espacosArtisticos: false,
    espacosArtisticosValue: '',
    documentacao: {},

    faixa: false,
    faixaValue: '',
    modalidadeIncisoIII: '',
    faixaUm: false,
    faixaDoisTres: false,
    formFaixaUm: false,
    formFaixaDoisTres: false,
    anexo: {},
    trabalho: {},
    inputLink: 1,
    links: {},
    linkSenha1: '',
    linkSenha2: '',
    linkSenha3: '',
    linkSenha4: '',
    linkSenha5: '',
    erros: {},
    alerta: false
  }

  uploadDocumentacao = files => {
    const _documentacao = files.map(file => ({
      file, id: uniqueId(),
      key: file.key,
      name: '_Documentacao.' + file.name.split('.').pop(),
      originalname: file.name,
      readableSize: filesize(file.size),
      preview: URL.createObjectURL(file),
      progress: 0,
      uploaded: false,
      error: false,
      url: URL.createObjectURL(file),
    }));
    this.setState({
      documentacao: _documentacao
    });

    _documentacao.forEach(this.processUploadDocumentacao);
  };


  updateFileDocumentacao = (id, data) => {
    this.setState({
      documentacao: this.state.documentacao.map(projeto => {
        return id === projeto.id
          ? { ...projeto, ...data }
          : projeto;
      })
    });
  };

  processUploadDocumentacao = async (documentacao) => {
    const { cpf } = this.state

    await axios.get(`${apiURL}/inscricoesLei/search/${cpf}`)
      .then(response => this.setState({ idInscricao: response.data.inscricoesLei.id }))
      .catch(errorHandling)
    const data = new FormData();

    data.append("file", documentacao.file, documentacao.name);
    api
      .put("/inscricoesLei/images/" + this.state.idInscricao, data, {
        onUploadProgress: e => {
          const progress = parseInt(Math.round((e.loaded * 100) / e.total));

          this.updateFileDocumentacao(documentacao.id, {
            progress
          });
        }
      })
      .then(response => {
        this.updateFileDocumentacao(response.data.inscricoes.id, {
          uploaded: true,
          id: response.data.inscricoes.id,
          url: response.data.inscricoes.url
        });
      })
      .catch(() => {
        this.updateFileDocumentacao(documentacao.id, {
          error: true
        });
      });
  };

  uploadAnexo = files => {
    const _anexo = files.map(file => ({
      file, id: uniqueId(),
      key: file.key,
      name: '_InscricaoLeiAnexo.' + file.name.split('.').pop(),
      originalname: file.name,
      readableSize: filesize(file.size),
      preview: URL.createObjectURL(file),
      progress: 0,
      uploaded: false,
      error: false,
      url: URL.createObjectURL(file),
    }));
    this.setState({
      anexo: _anexo
    });

    _anexo.forEach(this.processUploadAnexo);
  };


  updateFileAnexo = (id, data) => {
    this.setState({
      anexo: this.state.anexo.map(projeto => {
        return id === projeto.id
          ? { ...projeto, ...data }
          : projeto;
      })
    });
  };

  processUploadAnexo = async (anexo) => {
    const { cpf } = this.state

    await axios.get(`${apiURL}/inscricoesLei/search/${cpf}`)
      .then(response => this.setState({ idInscricao: response.data.inscricoesLei.id }))
      .catch(errorHandling)
    const data = new FormData();

    data.append("file", anexo.file, anexo.name);
    api
      .put("/inscricoesLei/images/" + this.state.idInscricao, data, {
        onUploadProgress: e => {
          const progress = parseInt(Math.round((e.loaded * 100) / e.total));

          this.updateFileAnexo(anexo.id, {
            progress
          });
        }
      })
      .then(response => {
        this.updateFileAnexo(response.data.inscricoes.id, {
          uploaded: true,
          id: response.data.inscricoes.id,
          url: response.data.inscricoes.url
        });
      })
      .catch(() => {
        this.updateFileAnexo(anexo.id, {
          error: true
        });
      });
  };

  uploadTrabalho = files => {
    const _trabalho = files.map(file => ({
      file, id: uniqueId(),
      key: file.key,
      name: '_InscricaoLeiTrabalho.' + file.name.split('.').pop(),
      originalname: file.name,
      readableSize: filesize(file.size),
      preview: URL.createObjectURL(file),
      progress: 0,
      uploaded: false,
      error: false,
      url: URL.createObjectURL(file),
    }));
    this.setState({
      trabalho: _trabalho
    });

    _trabalho.forEach(this.processUploadTrabalho);
  };


  updateFileTrabalho = (id, data) => {
    this.setState({
      trabalho: this.state.trabalho.map(projeto => {
        return id === projeto.id
          ? { ...projeto, ...data }
          : projeto;
      })
    });
  };

  processUploadTrabalho = async (trabalho) => {
    const { cpf } = this.state

    await axios.get(`${apiURL}/inscricoesLei/search/${cpf}`)
      .then(response => this.setState({ idInscricao: response.data.inscricoesLei.id }))
      .catch(errorHandling)
    const data = new FormData();

    data.append("file", trabalho.file, trabalho.name);
    api
      .put("/inscricoesLei/images/" + this.state.idInscricao, data, {
        onUploadProgress: e => {
          const progress = parseInt(Math.round((e.loaded * 100) / e.total));

          this.updateFileTrabalho(trabalho.id, {
            progress
          });
        }
      })
      .then(response => {
        this.updateFileTrabalho(response.data.inscricoes.id, {
          uploaded: true,
          id: response.data.inscricoes.id,
          url: response.data.inscricoes.url
        });
      })
      .catch(() => {
        this.updateFileTrabalho(trabalho.id, {
          error: true
        });
      });
  };


  handleOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {

    this.setState({ open: false });
  };

  async inserirNoBanco(event) {
    const form = event;
    const data = new FormData();

    function stringifyFormData(fd) {
      const data = {
        'cpf': event.cpf,
        'inciso_inscricao': event.incisoValue,
        'modalidade': event.modalidadeValue,
        'faixa': event.faixaValue,
        'modalidade_inciso_III': event.modalidadeIncisoIII
      };
      for (let key of fd.keys()) {
        data[key] = fd.get(key);
      }
      return JSON.stringify(data, null, 2);

    }

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = stringifyFormData(data);
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow"
    };

    fetch(apiURL + '/inscricoesLei', requestOptions)
      .then(response => response.json())
      .catch(error => error)
    if (event.incisoValue === '2') this.setState({ espacosArtisticos: true })
    if (event.faixaValue === '1') this.setState({ formFaixaUm: true })
    if (event.faixaValue === '2' || event.faixaValue === '3') this.setState({ formFaixaDoisTres: true })
  }

  atualizarInscricao(event) {
    const { linkSenha1,
      linkSenha2,
      linkSenha3,
      linkSenha4,
      linkSenha5, } = this.state
    var dados = []
    dados.push(linkSenha1, linkSenha2, linkSenha3, linkSenha4, linkSenha5)
    const form = event;
    const data = new FormData();

    function stringifyFormData(fd) {
      const data = {
        'nome_representante': event.nome_representante,
        'links': dados.toString()
      };
      for (let key of fd.keys()) {
        data[key] = fd.get(key);
      }
      return JSON.stringify(data, null, 2);

    }

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = stringifyFormData(data);
    var requestOptions = {
      method: "PUT",
      headers: myHeaders,
      body: raw,
      redirect: "follow"
    };

    fetch(apiURL + '/inscricoesLei/' + this.state.idInscricao, requestOptions)
      .then(response => response.json())
      .catch(error => error)

    window.location.href = "/"
  }
  onChangeInput = (field, value) => this.setState({ [field]: value })

  modalidadeContainer = () => (
    <div className='Teste-Modal'>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className='Modal'
        open={this.state.modalidade}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={this.state.modalidade}>
          <div className='Card-Modal'>
            <h2 id="transition-modal-title">Selecione a modalidade da inscrição</h2>
            <Select
              name='modalidade'
              onChange={(ev) => this.onChangeInput('modalidadeValue', ev.target.value)}
              value={this.state.modalidadeValue}
              opcoes={[
                { label: 'Grande Porte', value: 'GP' },
                { label: 'Médio Porte', value: 'MP' },
                { label: 'Pequeno Porte', value: 'PP' }
              ]}
            />

            <br />
            <div className='Botao-Modal flex flex-center'>
              <Button
                type='modal'
                label={'Voltar'}
                onClick={() => this.setState({ modalidade: false, inciso: true, modalidadeValue: '', incisoValue: '' })} />
              <Button
                type='modal'
                label={'Próximo'}
                onClick={() => this.inserirNoBanco(this.state)}
              />
            </div>
          </div>
        </Fade>
      </Modal>


    </div>
  )

  espacoArtisticoContainer = () => (
    <div className='Teste-Modal'>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className='Modal'
        open={this.state.espacosArtisticos}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={this.state.espacosArtisticos}>
          <div className='Card-Modal'>
            <h2 id="transition-modal-title">Insira a documentação</h2>
            <InputSimples
              type={'inputModal'}
              placeHolder={'Nome da organização/espaço...'}
              name='nomeOrganizacao'
              value={this.state.espacosArtisticosValue}
              onChange={(ev) => this.setState({ espacosArtisticosValue: ev.target.value })}
            />
            <br />
            <Content>
              <Paragraph>Arquivo de documentação (PDF*)</Paragraph>
              <Upload onUpload={this.uploadDocumentacao} /><br />
              {!!this.state.documentacao.length && (
                <FileList files={this.state.documentacao} />
              )}
            </Content>
            <div className='Botao-Modal flex flex-center'>
              <Button
                type='modal'
                label={'Finalizar Inscrição'}
                onClick={() => window.location.href = "/"} />
            </div>
          </div>
        </Fade>
      </Modal>
    </div>
  )


  nextStep = () => {
    (this.state.incisoValue == 2) && this.setState({ modalidade: true });
    (this.state.incisoValue == 3) && this.setState({ faixa: true });
    (this.state.faixaValue == 1) && this.setState({ faixaUm: true });
  }
  incisoContainer = () => (
    <div className='Teste-Modal'>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className='Modal'
        open={this.state.inciso}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={this.state.inciso}>
          <div className='Card-Modal'>
            <h2 id="transition-modal-title">Seleção de inciso</h2>
            <Select
              name='inciso'
              onChange={(ev) => this.onChangeInput('incisoValue', ev.target.value)}
              value={this.state.incisoValue}
              opcoes={[
                { label: 'Inciso II', value: '2' },
                { label: 'Inciso III', value: '3' }
              ]}
            />

            <br />
            <div className='Botao-Modal flex flex-center'>
              <Button
                type='modal'
                label={'Próximo'}
                onClick={() => this.nextStep()}
              />
            </div>
          </div>
        </Fade>
      </Modal>
    </div>
  )

  // INCISO III //

  faixaContainer = () => (
    <div className='Teste-Modal'>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className='Modal'
        open={this.state.faixa}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={this.state.faixa}>
          <div className='Card-Modal'>
            <h2 id="transition-modal-title">Selecione a faixa da inscrição</h2>
            <Select
              name='faixa'
              onChange={(ev) => this.onChangeInput('faixaValue', ev.target.value)}
              value={this.state.faixaValue}
              opcoes={[
                { label: 'Faixa 01', value: '1' },
                { label: 'Faixa 02', value: '2' },
                { label: 'Faixa 03', value: '3' }
              ]}
            />

            <br />
            <div className='Observacoes'>
              <div><strong>Faixa 01:</strong> - Cotas de R$ 2.500,00 para integrantes individuais ou dupla</div><br />
              <div><strong>Faixa 02:</strong> - Cotas de R$ 5.000,00 para coletivos/grupos de 3 a 5 integrantes</div>
              <u>Faixa exclusiva para as modalidade de dança, musica, e teatro/circo</u> (exceto para as alineas e.7, i.2 e i.3 do item 4 do Regulamento de Seleção de Propostas Artísticas - Lei Aldir Blanc Nº 01/2020)<br /><br />
              <div><strong>Faixa 03:</strong> - Cotas de R$ 10.000,00 para coletivos/grupos de 3 a 5 integrantes</div>
              <u>Faixa exclusiva para as modalidade de dança, musica, e teatro/circo</u> (exceto para as alineas e.7, i.2 e i.3 do item 4 do Regulamento de Seleção de Propostas Artísticas - Lei Aldir Blanc Nº 01/2020)
            </div>
            <div className='Botao-Modal flex flex-center'>
              <Button
                type='modal'
                label={'Voltar'}
                onClick={() => this.setState({ faixa: false, inciso: true, faixaValue: '' })} />
              <Button
                type='modal'
                label={'Próximo'}
                onClick={() => this.setState(this.state.faixaValue === '1' ? { faixaUm: true } : { faixaDoisTres: true })}
              />
            </div>
          </div>
        </Fade>
      </Modal>


    </div>
  )

  faixaUmContainer = () => (
    <div className='Teste-Modal'>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className='Modal'
        open={this.state.faixaUm}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={this.state.faixaUm}>
          <div className='Card-Modal'>
            <h2 id="transition-modal-title">Selecione a modalidade da inscrição</h2>
            <Select
              name='faixaUm'
              onChange={(ev) => this.onChangeInput('modalidadeIncisoIII', ev.target.value)}
              value={this.state.modalidadeIncisoIII}
              opcoes={[
                { label: 'ARTES VISUAIS - Individual ou duplas e coletivos artísticos', value: '1' },
                { label: 'AUDIOVISUAL', value: '2' },
                { label: 'CULTURA DE RUA - Cultura afro-brasileira e capoeira', value: '3' },
                { label: 'CULTURA DE RUA - Hip-Hop', value: '4' },
                { label: 'CULTURA POPULAR E ARTESANATO', value: '5' },
                { label: 'DANÇA - Oficinas virtuais', value: '6' },
                { label: 'LITERATURA - Escrita - Prosa ou Poesia', value: '7' },
                { label: 'LITERATURA - Poesia falada - Vídeo', value: '8' },
                { label: 'ECONOMIA CRIATIVA E CULTURAL DIGITAL', value: '9' },
                { label: 'MÚSICA - Autoral', value: '10' },
                { label: 'MÚSICA - Podcast', value: '11' },
              ]}
            />

            <br />
            <div className='Botao-Modal flex flex-center'>
              <Button
                type='modal'
                label={'Voltar'}
                onClick={() => this.setState({ faixaUm: false, inciso: true, modalidadeIncisoIII: '' })} />
              <Button
                type='modal'
                label={'Próximo'}
                onClick={() => this.inserirNoBanco(this.state)} />
            </div>
          </div>
        </Fade>
      </Modal>


    </div>
  )

  faixaDoisTresContainer = () => (
    <div className='Teste-Modal'>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className='Modal'
        open={this.state.faixaDoisTres}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={this.state.faixaDoisTres}>
          <div className='Card-Modal'>
            <h2 id="transition-modal-title">Selecione a modalidade da inscrição</h2>
            <Select
              name='modalidadeIncisoIII'
              onChange={(ev) => this.onChangeInput('modalidadeIncisoIII', ev.target.value)}
              value={this.state.modalidadeIncisoIII}
              opcoes={[
                { label: 'DANÇA - Performance', value: '1' },
                { label: 'DANÇA - Espetáculo de dança', value: '2' },
                { label: 'TEATRO E CIRCO - Espetáculo teatral/performance', value: '3' },
                { label: 'TEATRO E CIRCO - Número círcence', value: '4' },
                { label: 'MÚSICA - Show', value: '5' },
              ]}
            />

            <br />
            <div className='Botao-Modal flex flex-center'>
              <Button
                type='modal'
                label={'Voltar'}
                onClick={() => this.setState({ faixaDoisTres: false, faixa: true, modalidadeIncisoIII: '' })} />
              <Button
                type='modal'
                label={'Próximo'}
                onClick={() => this.inserirNoBanco(this.state)} />
            </div>
          </div>
        </Fade>
      </Modal>


    </div>
  )


  // FAIXA I //
  formFaixaUmContainer = () => (
    <div className='Teste-Modal'>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className='Modal'
        open={this.state.formFaixaUm}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={this.state.formFaixaUm}>
          <div className='Card-Modal'>
            <h2 id="transition-modal-title">Finalizando inscrição...</h2>
            <InputSimples
              type={'inputModal'}
              placeHolder={'Nome completo do representante...'}
              name='nome_representante'
              value={this.state.nome_representante}
              onChange={(ev) => this.setState({ nome_representante: ev.target.value })}
            />
            <br />
            <div>
              {(this.state.modalidadeIncisoIII === '1' ||
                this.state.modalidadeIncisoIII === '5') &&
                <div>
                  <div className='flex'>
                    <Content>
                      <Paragraph>Arquivo de identificação (item 8 do regulamento em um único PDF*)</Paragraph>
                      <Upload onUpload={this.uploadAnexo} /><br />
                      {!!this.state.anexo.length && (
                        <FileList files={this.state.anexo} />
                      )}
                    </Content>
                    <Content>
                      <Paragraph>Imagens dos trabalhos (Arquivo PDF até 10MB *)</Paragraph>
                      <Upload onUpload={this.uploadTrabalho} /><br />
                      {!!this.state.trabalho.length && (
                        <FileList files={this.state.trabalho} />
                      )}
                    </Content>
                  </div>
                </div>
              }
              {this.state.modalidadeIncisoIII === '2' &&
                <div>
                  <div className='flex horizontal'>
                    <Content>
                      <Paragraph>Arquivo de identificação (item 8 do regulamento em um único PDF*)</Paragraph>
                      <Upload onUpload={this.uploadAnexo} /><br />
                      {!!this.state.anexo.length && (
                        <FileList files={this.state.anexo} />
                      )}
                    </Content>
                  </div>
                </div>
              }
              {this.state.modalidadeIncisoIII === '3' ||
                this.state.modalidadeIncisoIII === '4' ||
                this.state.modalidadeIncisoIII === '6' ||
                this.state.modalidadeIncisoIII === '8' ||
                this.state.modalidadeIncisoIII === '10' ||
                this.state.modalidadeIncisoIII === '11' &&
                <div>
                  <div className='flex horizontal'>
                    <Content>
                      <Paragraph>Arquivo de identificação (item 8 do regulamento em um único PDF*)</Paragraph>
                      <Upload onUpload={this.uploadAnexo} /><br />
                      {!!this.state.anexo.length && (
                        <FileList files={this.state.anexo} />
                      )}
                    </Content>
                  </div>
                </div>
              }
              {(this.state.modalidadeIncisoIII === '3' ||
                this.state.modalidadeIncisoIII === '4' ||
                this.state.modalidadeIncisoIII === '6' ||
                this.state.modalidadeIncisoIII === '8' ||
                this.state.modalidadeIncisoIII === '10' ||
                this.state.modalidadeIncisoIII === '11') &&
                <div>
                  <div className='flex horizontal'>
                    <Content>
                      <Paragraph>Arquivo de identificação (item 8 do regulamento em um único PDF*)</Paragraph>
                      <Upload onUpload={this.uploadAnexo} /><br />
                      {!!this.state.anexo.length && (
                        <FileList files={this.state.anexo} />
                      )}
                    </Content>
                  </div>
                </div>
              }

              <div className='flex horizontal wrap'>Material audiovisual - Insira o link abaixo: Se possuir senha, insira seguida de <strong style={{ color: 'red' }}>&nbsp; ;</strong></div>
              <InputSimples
                type={'inputModal'}
                placeHolder={'LINK;SENHA'}
                name='inputLink'
                value={this.state.linkSenha1}
                onChange={(ev) => this.setState({ linkSenha1: ev.target.value })}
              />
              <br />
              {this.state.linkSenha1 &&
                <div>
                  <InputSimples
                    type={'inputModal'}
                    placeHolder={'LINK;SENHA'}
                    name='inputLink'
                    value={this.state.linkSenha2}
                    onChange={(ev) => this.setState({ linkSenha2: ev.target.value })}
                  />
                  <br />
                </div>
              }
              {this.state.linkSenha2 &&
                <div>
                  <InputSimples
                    type={'inputModal'}
                    placeHolder={'LINK;SENHA'}
                    name='inputLink'
                    value={this.state.linkSenha3}
                    onChange={(ev) => this.setState({ linkSenha3: ev.target.value })}
                  />
                  <br />
                </div>
              }
              {this.state.linkSenha3 &&
                <div>
                  <InputSimples
                    type={'inputModal'}
                    placeHolder={'LINK;SENHA'}
                    name='inputLink'
                    value={this.state.linkSenha4}
                    onChange={(ev) => this.setState({ linkSenha4: ev.target.value })}
                  />
                  <br />
                </div>
              }
              {this.state.linkSenha4 &&
                <div>
                  <InputSimples
                    type={'inputModal'}
                    placeHolder={'LINK;SENHA'}
                    name='inputLink'
                    value={this.state.linkSenha5}
                    onChange={(ev) => this.setState({ linkSenha5: ev.target.value })}
                  />
                </div>
              }
            </div>



            <div className='Botao-Modal flex flex-center'>
              <Button
                type='modal'
                label={'Voltar'}
                onClick={() => this.setState({ formFaixaUm: false, modalidadeIncisoIII: '' })} />
              <Button
                type='modal'
                label={'Finalizar Inscrição'}
                onClick={() => this.atualizarInscricao(this.state)} />
            </div>
          </div>
        </Fade>
      </Modal>
    </div>
  )

  formFaixaDoisTresContainer = () => (
    <div className='Teste-Modal'>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className='Modal'
        open={this.state.formFaixaDoisTres}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={this.state.formFaixaDoisTres}>
          <div className='Card-Modal'>
            <h2 id="transition-modal-title">Finalizando inscrição...</h2>
            <InputSimples
              type={'inputModal'}
              placeHolder={'Nome completo do representante...'}
              name='nomeOrganizacao'
              value={this.state.nome_representante}
              onChange={(ev) => this.setState({ nome_representante: ev.target.value })}
            />
            <br />
            <div>
              <div>
                <div className='flex horizontal'>
                  <Content>
                    <Paragraph>Arquivo de identificação (item 8 do regulamento em um único PDF*)</Paragraph>
                    <Upload onUpload={this.uploadAnexo} /><br />
                    {!!this.state.anexo.length && (
                      <FileList files={this.state.anexo} />
                    )}
                  </Content>
                </div>
              </div>
              <div className='flex horizontal wrap'>Material audiovisual - Insira o link abaixo: Se possuir senha, insira seguida de <strong style={{ color: 'red' }}>&nbsp; ;</strong></div>
              <InputSimples
                type={'inputModal'}
                placeHolder={'LINK;SENHA'}
                name='inputLink'
                value={this.state.linkSenha1}
                onChange={(ev) => this.setState({ linkSenha1: ev.target.value })}
              />
              <br />
            </div>



            <div className='Botao-Modal flex flex-center'>
              {/* <Button
                type='modal'
                label={'Voltar'}
                onClick={() => this.setState({ faixaDoisTres: true, formFaixaDoisTres: false, faixaDoisTresValue: '' })} /> */}
              <Button
                type='modal'
                label={'Finalizar Inscrição'}
                onClick={() => this.atualizarInscricao(this.state)} />
            </div>
          </div>
        </Fade>
      </Modal>
    </div>
  )

  ModalSimples(props) {
    const mCPF = (cpf) => {
      cpf = cpf.replace(/\D/g, "")
      cpf = cpf.replace(/(\d{3})(\d)/, "$1.$2")
      cpf = cpf.replace(/(\d{3})(\d)/, "$1.$2")
      cpf = cpf.replace(/(\d{3})(\d{1,2})$/, "$1-$2")
      return cpf
    }

    const ValidaCPF = () => {
      var RegraValida = this.state.cpf;
      var cpf = RegraValida.trim();

      cpf = cpf.replace(/\./g, '');
      cpf = cpf.replace('-', '');
      cpf = cpf.split('');

      var v1 = 0;
      var v2 = 0;
      var aux = false;

      for (var i = 1; cpf.length > i; i++) {
        if (cpf[i - 1] != cpf[i]) {
          aux = true;
        }
      }

      if (aux == false) {
        return false;
      }

      for (var i = 0, p = 10; (cpf.length - 2) > i; i++, p--) {
        v1 += cpf[i] * p;
      }

      v1 = ((v1 * 10) % 11);

      if (v1 == 10) {
        v1 = 0;
      }

      if (v1 != cpf[9]) {
        return false;
      }

      for (var i = 0, p = 11; (cpf.length - 1) > i; i++, p--) {
        v2 += cpf[i] * p;
      }

      v2 = ((v2 * 10) % 11);

      if (v2 == 10) {
        v2 = 0;
      }

      if (v2 != cpf[10]) {
        return false;
      } else {
        return true;
      }
    }
    const validate = () => {
      const cpf = this.state.cpf;
      const erros = {};
      if (!cpf) erros.cpf = 'Preencha aqui com seu cpf'
      if (!ValidaCPF()) erros.validaCpf = 'CPF inválido'
      this.setState({ erros });
      return !(Object.keys(erros).length > 0);
    }

    const buscarCpf = () => {
      const cpf = this.state.cpf;
      if (!validate()) return;

      axios.get(`${apiURL}/inscricoesLei/search/${cpf}`)
        .then(response => {
          if (response.data.inscricoesLei !== null) this.setState({ alerta: true })
          if (response.data.inscricoesLei === null) this.setState({ inciso: true })
        })
        .catch(errorHandling)
    }
    return (
      <div className='Teste-Modal'>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className='Modal'
          open={this.state.open}
          onClose={this.handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={this.state.open}>
            <div className='Card-Modal'>
              <InputSimples
                placeHolder={'Informe seu CPF...'}
                id='RegraValida'
                name='RegraValida'
                type={'cpf'}
                maxLength={'14'}
                value={this.state.cpf}
                onChange={(ev) => this.setState({ cpf: mCPF(ev.target.value), alerta: false }, () => validate())}
                error={this.state.erros.validaCpf}
              /><br />
              {
                this.state.alerta === true && <Alerta error='CPF já cadastrado' />
              }

              <div className='Botao-Modal flex flex-center'>
                <Button
                  type='modal'
                  onClick={() => buscarCpf()}
                  label={'Iniciar'} />
              </div>
            </div>
          </Fade>
        </Modal>
      </div>
    )

  }

  render() {
    const { linkSenha } = this.state;
    return (
      <div>
        <br />
        <div className="Candidato" >
          {/* <strong>Recursos Lei Aldir Blanc – Anápolis (Obrigatório)</strong><br />
          Inscrições para os Incisos II e III <strong>EM BREVE</strong><br />
          - Regulamento <strong>EM BREVE</strong> <br />
          - Anexos <strong>EM BREVE</strong> <br />
          - Formulário de Inscrição <strong>EM BREVE</strong>
          <br /><br /><br /> */}
          {/* <strong>Mapa Goiano - (Obrigatório)</strong><br />
          Para ter acesso a qualquer um dos incisos da Lei Aldir Blanc,
          você precisa estar cadastrado no Mapa Goiano. Caso ainda não
          tenha feito, não deixe de se cadastrar como agente ou agente
          coletivo ou espaço cultural. A cidade de Anápolis fez a adesão
          a esta plataforma desenvolvida pelo Governo de Goiás que oferece
          ferramentas que facilitam o cruzamento de dados e também torna
          mais ágil a avaliação das inscrições. Para acessar <a href='https://mapagoiano.cultura.go.gov.br' target='_blank'>clique aqui</a>
          <br /><br /><br />
          <strong>Em breve</strong> os incisos II pelo Município e os incisos II e III pelo Município e Estado. <br /><br />
          <strong>IMPORTANTE:<br />
          As inscrições para o auxílio emergencial aos artistas, repassados pelo Governo Estadual, foram prorrogadas até o dia 23/10</strong> */}

          <strong>Inscrições para espaços e agentes culturais (Incisos II e III)</strong><br /><br />

A Prefeitura de Anápolis fez a adesão ao Mapa Goiano, do Governo de Goiás, que oferece ferramentas que facilitam o cruzamento de dados e também torna mais ágil a avaliação das inscrições realizadas. Siga os seguintes passos:<br /><br />

          <strong>1)</strong> Para ter acesso a qualquer um dos incisos da Lei Aldir Blanc, você precisa estar cadastrado no Mapa Goiano.<br /><br />

          <strong>2)</strong> Realizado o cadastro, busque o item “Oportunidades” para encontrar os botões de acesso “Lei Aldir Blanc - Inciso II | Anápolis” ou “Lei Aldir Blanc - Inciso III | Anápolis”. Em cada um deles, você encontrará o regulamento e os anexos necessários para a inscrição.<br /><br />

          <strong>Acesse:</strong> <a href="https://mapagoiano.cultura.go.gov.br" target='_blanc'>www.mapagoiano.cultura.go.gov.br</a>

          <div style={{ display: 'none' }}>
            <Button type='success' label='Iniciar' onClick={() =>
              this.setState({ open: true })
            } />
          </div>

          <div className='Modal flex flex-center'>
            {this.ModalSimples()}
            {this.state.inciso === true && this.incisoContainer()}
            {this.state.modalidade === true && this.modalidadeContainer()}
            {this.state.espacosArtisticos === true && this.espacoArtisticoContainer()}
            {this.state.faixa === true && this.faixaContainer()}
            {this.state.faixaUm === true && this.faixaUmContainer()}
            {this.state.faixaDoisTres === true && this.faixaDoisTresContainer()}
            {this.state.formFaixaUm === true && this.formFaixaUmContainer()}
            {this.state.formFaixaDoisTres === true && this.formFaixaDoisTresContainer()}

          </div>

        </div>
      </div>

    )
  }
}

export default CadastroCultura
