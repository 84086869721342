import React, { useState } from 'react';
import Instrucoes from './instrucoes';
import IniciarInscricao from './iniciarInscricao';
import DadosPessoais from './dadosPessoais';
import ConfirmarInscricao from './confirmarInscricao';
import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';
import { useTheme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import UploadImagens from '../uploadImagem/index';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}


export default function FullWidthTabs() {

  const theme = useTheme();
  const [value, setValue] = React.useState(0);

  const [cpf, setCpf] = useState('')
  const [tipoInscricao, setTipoInscricao] = useState('')
  const [nomeArtistico, setNomeArtistico] = useState('')
  const [nomeCompleto, setNomeCompleto] = useState('')
  const [rg, setRg] = useState('')
  const [dataNascimento, setDataNascimento] = useState('')
  const [nomeMae, setNomeMae] = useState('')
  const [cep, setCep] = useState('')
  const [logradouro, setLogradouro] = useState('')
  const [n, setN] = useState('')
  const [complemento, setComplemento] = useState('')
  const [bairro, setBairro] = useState('')
  const [cidade, setCidade] = useState('')
  const [uf, setUf] = useState('')
  const [email, setEmail] = useState('')
  const [categoria, setCategoria] = useState('')
  const [uploadedFiles, setUploadedFiles] = useState('')

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  return (
    <div>
      <div className="tab">
        <AppBar position="static" color="default">
          <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="primary"
            variant="fullWidth"
            aria-label="full width tabs example"
          >
            <Tab label="Instruções" {...a11yProps(0)} />
            <Tab label="Iniciar inscrição" {...a11yProps(1)} />
            <Tab label="Dados Pessoais" {...a11yProps(2)} disabled={!cpf || !tipoInscricao} />
            <Tab label="Upload Arquivos" {...a11yProps(3)}
            // disabled={
            // !cpf ||
            // !tipoInscricao ||
            // !nomeArtistico ||
            // !nomeCompleto ||
            // !rg ||
            // !dataNascimento ||
            // !nomeMae ||
            // !cep ||
            // !logradouro ||
            // !n ||
            // !complemento ||
            // !bairro ||
            // !cidade ||
            // !uf ||
            // !email ||
            // !categoria} 
            />
            <Tab label="Confirmar e Enviar" {...a11yProps(4)}
            // disabled={
            //   !cpf ||
            //   !tipoInscricao ||
            //   !nomeArtistico ||
            //   !nomeCompleto ||
            //   !rg ||
            //   !dataNascimento ||
            //   !nomeMae ||
            //   !cep ||
            //   !logradouro ||
            //   !n ||
            //   !complemento ||
            //   !bairro ||
            //   !cidade ||
            //   !uf ||
            //   !email ||
            //   !categoria
            //   }
            />
          </Tabs>
        </AppBar>
        <SwipeableViews
          axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
          index={value}
          onChangeIndex={handleChangeIndex}
        >
          <TabPanel value={value} index={0} dir={theme.direction}>
            <Instrucoes />
          </TabPanel>

          <TabPanel value={value} index={1} dir={theme.direction}>
            <IniciarInscricao
              cpf={cpf} tipoInscricao={tipoInscricao}
              cpfMask={setCpf} tipoInscricaoChanged={setTipoInscricao}
            >
            </IniciarInscricao>
          </TabPanel>

          <TabPanel value={value} index={2} dir={theme.direction}>
            <DadosPessoais
              cpf={cpf} cpfMask={setCpf}
              nomeArtistico={nomeArtistico} nomeArtisticoChanged={setNomeArtistico}
              nomeCompleto={nomeCompleto} nomeCompletoChanged={setNomeCompleto}
              rg={rg} rgChanged={setRg}
              dataNascimento={dataNascimento} dataNascimentoChanged={setDataNascimento}
              nomeMae={nomeMae} nomeMaeChanged={setNomeMae}
              cep={cep} cepChanged={setCep}
              logradouro={logradouro} logradouroChanged={setLogradouro}
              n={n} nChanged={setN}
              complemento={complemento} complementoChanged={setComplemento}
              bairro={bairro} bairroChanged={setBairro}
              cidade={cidade} cidadeChanged={setCidade}
              uf={uf} ufChanged={setUf}
              email={email} emailChanged={setEmail}
              categoria={categoria} categoriaChanged={setCategoria}>
            </DadosPessoais>
          </TabPanel>
          <TabPanel value={value} index={3} dir={theme.direction}>
            <UploadImagens />
          </TabPanel>
          <TabPanel value={value} index={4} dir={theme.direction}>
            <ConfirmarInscricao
              cpf={cpf} cpfMask={setCpf}
              tipoInscricao={tipoInscricao} tipoInscricaoChanged={setTipoInscricao}
              nomeArtistico={nomeArtistico} nomeArtisticoChanged={setNomeArtistico}
              nomeCompleto={nomeCompleto} nomeCompletoChanged={setNomeCompleto}
              rg={rg} rgChanged={setRg}
              dataNascimento={dataNascimento} dataNascimentoChanged={setDataNascimento}
              nomeMae={nomeMae} nomeMaeChanged={setNomeMae}
              cep={cep} cepChanged={setCep}
              logradouro={logradouro} logradouroChanged={setLogradouro}
              n={n} nChanged={setN}
              complemento={complemento} complementoChanged={setComplemento}
              bairro={bairro} bairroChanged={setBairro}
              cidade={cidade} cidadeChanged={setCidade}
              uf={uf} ufChanged={setUf}
              email={email} emailChanged={setEmail}
              categoria={categoria} categoriaChanged={setCategoria}
              uploadedFiles={uploadedFiles} onUpload={setUploadedFiles}>
            </ConfirmarInscricao>
          </TabPanel>
        </SwipeableViews>
      </div>
    </div>
  );
}