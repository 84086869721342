import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import Pdfcatalogo24 from './files/catalago24.pdf';
import PdfCuradoria from './files/Catalogo23SalaoPremioCuradoria.pdf';
import PdfPremiados from './files/Catalogo23SalaoPremiados.pdf';
import PdfSelecionados from './files/Catalogo23SalaoSelecionados.pdf';
import PdfConvergencia from './files/catalogoconvergencia.pdf';
import PdfCatalogo from './files/catalogo.pdf';
import Pdf21salao from './files/21SALAO.pdf';
import Pdf20salao from './files/20SALAO.pdf';
import Pdf19salao from './files/19SALAO.pdf';
import Pdf18salao from './files/18SALAO.pdf';
import Pdf17salao from './files/17SALAO.pdf';
import Pdf14salao from './files/14SALAO.pdf';
import PdfArtistasAnteriores from './files/Artistas_Anteriores.pdf';
import PdfComissoesAnteriores from './files/Comissoes_Anteriores.pdf';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: 560,
    backgroundColor: theme.palette.background.paper,
  },
}));

function ListItemLink(props) {
  return <ListItem button component="a" {...props} />;
}

export default function SimpleList() {
  const classes = useStyles();


  return (
    <div className={classes.root}>
      <br></br>
      Catálogos<hr></hr>
      <List component="nav" aria-label="main mailbox folders">
        <ListItemLink href={Pdfcatalogo24} target="_blank">
          <PictureAsPdfIcon style={{ color: 'red' }} />
          <ListItemText primary="Catálogo do 24º Salão Anapolino de Arte" />
        </ListItemLink>

        <ListItemLink href={PdfCuradoria} target="_blank">
          <PictureAsPdfIcon style={{ color: 'red' }} />
          <ListItemText primary="Catálogo do 23º Salão Anapolino de Arte - Prêmio Curadoria" />
        </ListItemLink>

        <ListItemLink href={PdfPremiados} target="_blank">
          <PictureAsPdfIcon style={{ color: 'red' }} />
          <ListItemText primary="Catálogo do 23º Salão Anapolino de Arte - Artistas Premiados" />
        </ListItemLink>

        <ListItemLink href={PdfSelecionados} target="_blank">
          <PictureAsPdfIcon style={{ color: 'red' }} />
          <ListItemText primary="Catálogo do 23º Salão Anapolino de Arte - Artistas Selecionados" />
        </ListItemLink>

        <ListItemLink href={PdfConvergencia} target="_blank">
          <PictureAsPdfIcon style={{ color: 'red' }} />
          <ListItemText primary="Catálogo Ponto de Convergência - 22º Salão Anapolino de Arte" />
        </ListItemLink>

        <ListItemLink href={PdfCatalogo} target="_blank">
          <PictureAsPdfIcon style={{ color: 'red' }} />
          <ListItemText primary="Catálogo do 22º Salão Anapolino de Arte" />
        </ListItemLink>

        <ListItemLink href={Pdf21salao} target="_blank">
          <PictureAsPdfIcon style={{ color: 'red' }} />
          <ListItemText primary="Catálogo do 21º Salão Anapolino de Arte" />
        </ListItemLink>

        <ListItemLink href={Pdf20salao} target="_blank">
          <PictureAsPdfIcon style={{ color: 'red' }} />
          <ListItemText primary="Catálogo do 20º Salão Anapolino de Arte" />
        </ListItemLink>

        <ListItemLink href={Pdf19salao} target="_blank">
          <PictureAsPdfIcon style={{ color: 'red' }} />
          <ListItemText primary="Catálogo do 19º Salão Anapolino de Arte" />
        </ListItemLink>

        <ListItemLink href={Pdf18salao} target="_blank">
          <PictureAsPdfIcon style={{ color: 'red' }} />
          <ListItemText primary="Catálogo do 18º Salão Anapolino de Arte" />
        </ListItemLink>

        <ListItemLink href={Pdf17salao} target="_blank">
          <PictureAsPdfIcon style={{ color: 'red' }} />
          <ListItemText primary="Catálogo do 17º Salão Anapolino de Arte" />
        </ListItemLink>

        <ListItemLink href={Pdf14salao} target="_blank">
          <PictureAsPdfIcon style={{ color: 'red' }} />
          <ListItemText primary="Catálogo do 14º Salão Anapolino de Arte" />
        </ListItemLink>

        <br></br>
      Outros<hr></hr>
        <ListItemLink href={PdfArtistasAnteriores} target="_blank">
          <PictureAsPdfIcon style={{ color: 'red' }} />
          <ListItemText primary="Artistas Anteriores" />
        </ListItemLink>

        <ListItemLink href={PdfComissoesAnteriores} target="_blank">
          <PictureAsPdfIcon style={{ color: 'red' }} />
          <ListItemText primary="Comissões Anteriores" />
        </ListItemLink>

      </List>
    </div>
  );
}