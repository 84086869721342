import React from 'react'
import { Link } from 'react-router-dom';

const Button = ({ type, onClick, label, icon }) => (
    <div className='Button'>
        <button onClick={onClick}
            className={`button button-${type || 'default'}`} >
            <i className={icon}></i> {label}
        </button>
    </div>
)

const ButtonSimples = ({ type, rota, onClick, label, icon }) => {
    if (rota) {
        return (
            <Link to={rota}>
                <Button type={type}
                    onClick={onClick}
                    icon={icon}
                    label={label} />
            </Link>
        );
    } else {
        return (
            <Button type={type}
                onClick={onClick}
                icon={icon}
                label={label} />
        )
    }
}

export default ButtonSimples