import 'date-fns';
import React, { Component } from 'react';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import './style.css';
const $ = require('jquery');

class DadosPessoais extends Component {


  constructor(props) {
    super(props);
    this.state = {
      cpf: '',
      bairro: ''
    };
  }

  onSearchSubmit = async cpf => {
    const response = await this.props.state.cpf
    this.setState({ cpf: response.data })
  }

  render() {

    $(document).ready(function () {

      function limpa_formulário_cep() {
        // Limpa valores do formulário de cep.
        $("#rua").val("");
        $("#bairro").val("");
        $("#cidade").val("");
        $("#uf").val("");
        $("#ibge").val("");
      }

      //Quando o campo cep perde o foco.
      $("#cep").blur(function () {

        //Nova variável "cep" somente com dígitos.
        var cep = $(this).val().replace(/\D/g, '');

        //Verifica se campo cep possui valor informado.
        if (cep !== "") {

          //Expressão regular para validar o CEP.
          var validacep = /^[0-9]{8}$/;

          //Valida o formato do CEP.
          if (validacep.test(cep)) {

            //Preenche os campos com "..." enquanto consulta webservice.
            $("#logradouro").val("...");
            $("#bairro").val("...");
            $("#cidade").val("...");
            $("#uf").val("...");
            $("#ibge").val("...");

            //Consulta o webservice viacep.com.br/
            $.getJSON("https://viacep.com.br/ws/" + cep + "/json/?callback=?", function (dados) {

              if (!("erro" in dados)) {
                //Atualiza os campos com os valores da consulta.
                $("#logradouro").val(dados.logradouro);
                $("#bairro").val(dados.bairro);
                $("#cidade").val(dados.localidade);
                $("#uf").val(dados.uf);
                $("#ibge").val(dados.ibge);
              } //end if.
              else {
                //CEP pesquisado não foi encontrado.
                limpa_formulário_cep();
                alert("CEP não encontrado.");
              }
            });
          } //end if.
          else {
            //cep é inválido.
            limpa_formulário_cep();
          }
        } //end if.
        else {
          //cep sem valor, limpa formulário.
          limpa_formulário_cep();
        }
      });
    });


    const {
      nomeArtistico,
      nomeCompleto,
      rg,
      dataNascimento,
      nomeMae,
      cep,
      logradouro,
      n,
      complemento,
      bairro,
      cidade,
      uf,
      email,
      categoria,
    } = this.props

    return (

      <div>
        <form noValidate autoComplete="off" >
          <div>

            <div className="form">
              <TextField
                id="outlined-required"
                className="campo_form"
                label="Nome artístico"
                variant="outlined"
                margin="normal"
                value={nomeArtistico}
                onChange={e => this.props.nomeArtisticoChanged(e.target.value)}
              />

              <TextField
                id="outlined-required"
                label="Nome completo"
                className="campo_form"
                variant="outlined"
                margin="normal"
                value={nomeCompleto}
                onChange={e => this.props.nomeCompletoChanged(e.target.value)}
              />

              <TextField
                id="outlined-required"
                label="RG"
                variant="outlined"
                margin="normal"
                className="campo_form"
                value={rg}
                onChange={e => this.props.rgChanged(e.target.value)}
              />

              <TextField
                id="date"
                label="Data de Nascimento"
                type="date"
                defaultValue=""
                className="campo_form"
                InputLabelProps={{
                  shrink: true,
                }}
                value={dataNascimento}
                onChange={e => this.props.dataNascimentoChanged(e.target.value)}
              />

              <TextField
                id="outlined-required"
                label="Nome da mãe"
                variant="outlined"
                className="campo_form"
                margin="normal"
                value={nomeMae}
                onChange={e => this.props.nomeMaeChanged(e.target.value)}
              />

              <TextField
                className="campo_form"
                label="CEP"
                variant="outlined"
                id="cep"
                name="cep"
                margin="normal"
                value={cep}
                onChange={e => this.props.cepChanged(e.target.value)}
              />

              <TextField
                id="logradouro"
                name="logradouro"
                className="campo_form"
                label="Logradouro"
                variant="outlined"
                margin="normal"
                value={logradouro}
                onChange={e => this.props.logradouroChanged(e.target.value)}
              />

              <TextField
                className="campo_form"
                id="n"
                name="n"
                label="Nº"
                variant="outlined"
                margin="normal"
                value={n}
                onChange={e => this.props.nChanged(e.target.value)}
              />
              <TextField
                id="complemento"
                name="complemento"
                label="Complemento"
                variant="outlined"
                className="campo_form"
                margin="normal"
                value={complemento}
                onChange={e => this.props.complementoChanged(e.target.value)}
              />

              <TextField
                id="bairro"
                name="bairro"
                label="Bairro"
                variant="outlined"
                className="campo_form"
                margin="normal"
                value={bairro}
                onChange={e => this.props.bairroChanged(e.target.value)}
              />
              <TextField
                className="campo_form"
                label="Cidade"
                variant="outlined"
                id="cidade"
                name="cidade"
                margin="normal"
                value={cidade}
                onChange={e => this.props.cidadeChanged(e.target.value)}
              />
              <TextField
                id="uf"
                name="uf"
                label="Estado"
                variant="outlined"
                className="campo_form"
                margin="normal"
                value={uf}
                onChange={e => this.props.ufChanged(e.target.value)}
              />

              <TextField
                id="email"
                name="email"
                label="E-mail"
                variant="outlined"
                className="campo_form"
                margin="normal"
                value={email}
                onChange={e => this.props.emailChanged(e.target.value)}
              />

              <FormControl className="campo_form">
                <InputLabel id="demo-controlled-open-select-label">Categoria</InputLabel>
                <Select
                  labelId="demo-controlled-open-select-label"
                  id="demo-controlled-open-select"
                  open={this.open}
                  onClose={this.handleClose}
                  onOpen={this.handleOpen}
                  value={categoria}
                  onChange={e => this.props.categoriaChanged(e.target.value)}
                >
                  <MenuItem value="">
                    <em>Selecione...</em>
                  </MenuItem>
                  <MenuItem value={1}>Desenho</MenuItem>
                  <MenuItem value={2}>Escultura</MenuItem>
                  <MenuItem value={3}>Fotografia</MenuItem>
                  <MenuItem value={4}>Gravura</MenuItem>
                  <MenuItem value={5}>Instalação</MenuItem>
                  <MenuItem value={6}>Objeto</MenuItem>
                  <MenuItem value={7}>Performance</MenuItem>
                  <MenuItem value={8}>Pintura</MenuItem>
                  <MenuItem value={9}>Videoarte</MenuItem>
                  <MenuItem value={10}>Outra</MenuItem>
                </Select>
              </FormControl>

            </div>
          </div>
        </form>
      </div>
    )
  }
}

export default DadosPessoais;