import React, { Component } from 'react'

export default class LandingPage extends Component {
  render() {
    return (
      <div className='Landing-Page flex vertical'>
        <div className='Apresentacao'>
          O Fundo Municipal de Cultura (FMC), edição 2020, está com inscrições abertas para projetos da sociedade a serem contemplados pelo financiamento municipal. São <strong>800 mil reais</strong> distribuídos em nove áreas de linguagens artísticas, com 32 cotas de tipos de projetos como produção de filmes, festivais, publicação de livros, circulação de grupos artísticos e outros.
          <br /><br />Leia o regulamento e confira como você pode montar a sua proposta de ação cultural:
      </div>
      </div>
    )
  }
}