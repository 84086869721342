import React, { Component } from 'react'

export default class CalendarioLandingPage extends Component {
  render() {
    return (
      <div>
        <h1 className='Titulo-Landing-Page'>Calendário</h1>
        <hr style={{ margin: '1% 10% 5% 10%' }} />
        <table class="Calendario-Landing-Page" border="1">
          <tr><td>Inscrições dos Projetos</td><td>08 de setembro a 29 de dezembro de 2020</td></tr>

          <tr><td>Abertura do envelope</td><td>11 a 15 de janeiro de 2021</td></tr>

          <tr><td>Divulgação dos habilitados</td><td>25 de fevereiro de 2021</td></tr>

          <tr><td>Prazo recurso</td><td>26 de fevereiro a 04 de março de 2021</td></tr>

          <tr><td>Resposta recursal</td><td>12 de março de 2021</td></tr>

          <tr><td>Análise da documentação do Projeto</td><td>22 a 31 de março de 2021</td></tr>

          <tr><td>Divulgação dos projetos selecionados</td><td>06 de abril de 2021</td></tr>

          <tr><td>Prazo recurso</td><td>07 a 13 de abril de 2021</td></tr>

          <tr><td>Resposta recursal</td><td>26 de abril de 2021</td></tr>

          <tr><td>Homologação dos projetos selecionados</td><td>Até 05 de maio de 2021</td></tr>

        </table>
      </div>
    )
  }
}