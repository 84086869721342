import React from 'react';
import api, { apiURL } from '../../services/api';
import Menu from '../menu'
import Select from '../../components/Inputs/Select'
import './style.css'
import $ from 'jquery'
import { CircularProgressbar } from "react-circular-progressbar";
import { MdCheckCircle, MdError } from "react-icons/md";

$(document).on("input", "#titulo", function () {
  var limite = 255;
  var caracteresDigitados = $(this).val().length;
  var caracteresRestantes = limite - caracteresDigitados;

  $(".caracteresTitulo").text(caracteresRestantes);
});

const inputParsers = {
  date(input) {
    const [month, day, year] = input.split("/");
    return `${year}-${month}-${day}`;
  },
  uppercase(input) {
    return input.toUpperCase();
  },
  number(input) {
    return parseFloat(input);
  }
};

export default function FormIdentificacao() {
  const [file, setFile] = React.useState({})
  const [arrayMap, setArrayMap] = React.useState(false)
  const [produtoCultural, setProdutoCultural] = React.useState({})
  const [formFile, setFormFile] = React.useState(false)
  const [checkProgress, setCheckProgress] = React.useState('')
  const [progress, setProgess] = React.useState(0); // progess bar
  const [dataFile, getFile] = React.useState({ name: "", path: "" });
  const el = React.useRef(); // accesing input element


  const [values, setValues] = React.useState({
    title: '',
    author: '',
    category: '',
    url: ''
  });

  //CARREGA DADOS DO BANCO PELO ID
  const loadProdutoCultural = (id) => {
    api.get('/api/v1/culturalProduct/' + id)
      .then(response => {
        setProdutoCultural({ produtoCultural: response.data.produtoCultural })
      })
      .catch(error => { console.log(error) });
  }

  const getToken = () => {
    const token1 = localStorage.getItem('token1');
    const token2 = localStorage.getItem('token2');
    const token3 = localStorage.getItem('token3');
    if (!token1 || !token2 || !token3) return null;
    return `${token1}.${token2}.${token3}`;
  }

  //REGISTRO NO BANCO
  async function handleSubmit(event) {

    event.preventDefault();
    const form = event.target;
    const data = new FormData(form);
    for (let name of data.keys()) {
      const input = form.elements[name];
      const parserName = input.dataset.parse;
      if (parserName) {
        const parser = inputParsers[parserName];
        const parsedValue = parser(data.get(name));
        data.set(name, parsedValue);

      }
    }
    function stringifyFormData(fd) {
      const data = {};
      for (let key of fd.keys()) {
        data[key] = fd.get(key);
      }
      return JSON.stringify(data, null, 2);
    }

    const myHeaders = {
      'Content-Type': 'application/json',
      'authorization': `Bearer ${getToken()}`
    }

    const raw = stringifyFormData(data);

    const body = raw;
    await api.post("/api/v1/culturalProduct", body, {
      headers: myHeaders
    })
      .then(response => setProdutoCultural(response.data.culturalProduct.id))
      .then(response => {
        (
          values.category === 'Audiovisual' ||
          values.category === 'Cultura de rua' ||
          values.category === 'Cultura Popular Artesanato Videos' ||
          values.category === 'Dança' ||
          values.category === 'Literatura Falada' ||
          values.category === 'Teatro' ||
          values.category === 'Economia' ||
          values.category === 'Musica') ?
          document.location.href = '/dashboard/produtoCultural' :
          setFormFile({ formFile: true })
      })
      // .then(() => document.location.href = '/dashboard/produtoCultural')
      .catch(error => { console.log(error) });
  }

  //CARREGAR ARQUIVO
  const handleChangeFile = (e) => {
    setProgess(0)
    const file = e.target.files[0]; // accesing file
    setFile(file); // storing file
  }

  //UPLOAD ARQUIVO NO BANCO
  async function uploadFile() {
    const formData = new FormData();
    formData.append('file', file)
    const myHeaders = {
      'Content-Type': 'multipart/form-data',
      'authorization': `Bearer ${getToken()}`
    }

    await api.put(
      "/api/v1/culturalProduct/file/" +
      produtoCultural + '?' +
      `category=${values.category === 'Artes Visuais' ? 'artesVisuais' : values.category === 'Cultura Popular Artesanato' ? 'culturaPopular' : 'literaturaEscrita'}`,
      formData, {
      onUploadProgress: (ProgressEvent) => {
        let progress = Math.round(
          ProgressEvent.loaded / ProgressEvent.total * 100);
        setProgess(progress)
      },
      headers: myHeaders
    }).then(res => {
      getFile({
        name: res.data.name,
        path: apiURL + res.data.path
      },
        setCheckProgress({ checkProgress: true })
      )
      return document.location.href = '/dashboard/produtoCultural'
    })
      .catch(err => setCheckProgress({ checkProgress: false }))

  }


  const handleChange = name => event => {
    setValues({
      ...values,
      [name]: event.target.value,
    });
  };

  return (
    <div>
      <Menu />

      <form className="container-slide-index" enctype="multipart/form-data" onSubmit={handleSubmit}>
        <p className="titulo">Cadastrar Produto Cultural</p>
        <hr />
        <div className="content-slide-index">
          <div className="input-slide-index flex vertical">
            <strong>Categoria</strong>
            <select
              onChange={handleChange('category')}
              name="category"
              value={values.category}
            >
              <option value="">Selecionar...</option>
              <option value="Artes Visuais">Artes Visuais</option>
              <option value="Audiovisual">Audiovisual</option>
              <option value="Cultura de rua">Cultura de Rua / Afro-Brasileira</option>
              <option value="Cultura Popular Artesanato">Cultura Popular e Artesanato</option>
              <option value="Cultura Popular Artesanato Videos">Cultura Popular e Artesanato - Vídeos</option>
              <option value="Dança">Dança</option>
              <option value="Literatura Escrita">Literatura - Categoria Escrita</option>
              <option value="Literatura Falada">Literatura - Categoria Falada</option>
              <option value="Teatro">Teatro e circo</option>
              <option value="Economia">Economia Criativa e Cultura digital</option>
              <option value="Musica">Música / Podcast</option>
            </select><br></br>
            <div className='Cadastro-Produto-Cultural flex horizontal'>
              <div className='flex vertical flex-1'>
                <textarea id="titulo"
                  className="textarea-slide-index"
                  placeholder="Digite o título da notícia..."
                  name="title"
                  value={values.title}
                  onChange={handleChange('title')}
                  title="title"
                >
                </textarea> <br></br>
                <span class="caracteresTitulo">255</span> Restantes
              </div>

              <div className='flex vertical flex-1'>
                <textarea id="titulo"
                  className="textarea-slide-index"
                  placeholder="Digite o nome do autor..."
                  name="author"
                  value={values.author}
                  onChange={handleChange('author')}
                  author="author"
                >
                </textarea> <br></br>
                <span class="caracteresTitulo">255</span> Restantes
              </div>
            </div>

            {(
              values.category === 'Audiovisual' ||
              values.category === 'Cultura de rua' ||
              values.category === 'Cultura Popular Artesanato Videos' ||
              values.category === 'Dança' ||
              values.category === 'Literatura Falada' ||
              values.category === 'Teatro' ||
              values.category === 'Economia' ||
              values.category === 'Musica') &&
              <div style={{ alignItems: 'center', marginTop: '20px' }} className='flex vertical flex-1'>
                <textarea id="titulo"
                  className="textarea-slide-index"
                  placeholder="Digite a URL do vídeo..."
                  name="url"
                  value={values.url}
                  onChange={handleChange('url')}
                  url="url"
                >
                </textarea>
              </div>
            }


          </div>
        </div>
        <button style={(formFile === false) ? { backgroundColor: '#241F56', color: 'white' } : { backgroundColor: '#9e9e9e', color: 'black' }} className="btn-slide-index" disabled={(formFile === false) ? false : true} type="submit">Cadastrar</button>
      </form>

      <div style={(formFile === false) ? { display: 'none' } : { display: 'block' }} className="container-slide-index">
        <input className="inputFile" type="file" ref={el} onChange={handleChangeFile} />

        {checkProgress.checkProgress === true &&
          checkProgress.checkProgress === false && (
            <CircularProgressbar
              styles={{
                root: { width: 24 },
                path: { stroke: "#7159c1" }
              }}
              strokeWidth={10}
              value={progress}
            />
          )}

        {(checkProgress.checkProgress === true) && <MdCheckCircle size={24} color="#78e5d5" />}
        {checkProgress.checkProgress === false && <MdError size={24} color="#e57878" />}
        <br></br>
        {checkProgress.checkProgress === false && <p> Arquivo não suportado, insira uma IMAGEM</p>}
        <br></br>
        <button onClick={uploadFile} className="upbutton">Upload</button>
        <hr />
      </div>
    </div>
  );
}
