import React, { Component } from 'react';
import Login from './Login';
import Sidebar from './sidebar'
import Footer from './footer'
import InputSimples from './components/Inputs/Material'
import InputLogin from './components/Inputs/Login'
import Button from './components/Button/Simples'
import Checkbox from './components/Inputs/Checkbox';
import { api } from './services/api'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default class IndexLogin extends Component {
  state = {
    cpf: '',
    password: '',
    opcaoLembrar: true,
    erros: {}
  }

  onChangeInput = (field, ev) => {
    this.setState({ [field]: ev.target.value })
    this.validate()
  }
  onChangeCheckBox = (field) => this.setState({ [field]: !this.state[field] })

  saveToken = (usuario) => {
    if (!usuario.data.token) return null;
    const [token1, token2, token3] = usuario.data.token.split('.')
    localStorage.setItem("token1", token1);
    localStorage.setItem("token2", token2);
    localStorage.setItem("token3", token3);
  }

  notify = (e) => toast.error(e.errors);

  handleLogin = () => {
    const { cpf, password } = this.state

    api.post(`/api/v1/user/login`, { username: cpf, password })
      .then((response) => {
        this.saveToken(response.data);
      })
      .then((response) => window.location.href = '/dashboard')
      .catch((e) => this.notify(e.response.data));
  }

  mCPF = (cpf) => {
    cpf = cpf.replace(/\D/g, "")
    cpf = cpf.replace(/(\d{3})(\d)/, "$1.$2")
    cpf = cpf.replace(/(\d{3})(\d)/, "$1.$2")
    cpf = cpf.replace(/(\d{3})(\d{1,2})$/, "$1-$2")
    return cpf
  }

  ValidaCPF = () => {
    var RegraValida = this.state.cpf;
    var cpf = RegraValida.trim();

    cpf = cpf.replace(/\./g, '');
    cpf = cpf.replace('-', '');
    cpf = cpf.split('');
    var v1 = 0;
    var v2 = 0;
    var aux = false;

    for (var i = 1; cpf.length > i; i++) {
      if (cpf[i - 1] != cpf[i]) {
        aux = true;
      }
    }

    if (aux == false) {
      return false;
    }

    for (var i = 0, p = 10; (cpf.length - 2) > i; i++, p--) {
      v1 += cpf[i] * p;
    }

    v1 = ((v1 * 10) % 11);

    if (v1 == 10) {
      v1 = 0;
    }

    if (v1 != cpf[9]) {
      return false;
    }

    for (var i = 0, p = 11; (cpf.length - 1) > i; i++, p--) {
      v2 += cpf[i] * p;
    }

    v2 = ((v2 * 10) % 11);

    if (v2 == 10) {
      v2 = 0;
    }

    if (v2 != cpf[10]) {
      return false;
    } else {
      return true;
    }
  }

  validate() {
    const { cpf, password } = this.state;
    const erros = {};
    if (!cpf) erros.cpf = 'Preencha aqui com seu e-mail'
    if (!password) erros.password = 'Preencha aqui com sua senha'
    this.setState({ erros });
    return !(Object.keys(erros).length > 0);
  }

  render() {
    const { cpf, password, opcaoLembrar, erros } = this.state;

    return (
      <div className='flex flex-center'>
        <div>
          <Sidebar />
          <ToastContainer />
          <div className='Login flex vertical'>
            <InputLogin
              validate={this.ValidaCPF() === false ? false : true}
              label='CPF'
              value={cpf}
              type='cpf'
              erro={this.ValidaCPF() === false ? true : false}
              helpText={'CPF inválido'}
              id='RegraValida'
              name='RegraValida'
              onChange={(e) => this.setState({ cpf: this.mCPF(e.target.value) }, () => this.validate())}
            />
            <InputLogin
              className='input-login'
              placeHolder={'Digite sua senha'}
              value={password}
              error={erros.password}
              type='password'
              onChange={(ev) => this.onChangeInput('password', ev)} />
            <br /> <br />
            <div className='btn-login flex flex-center'>
              <Button type='login' label={<i class='fa fa-paper-plane' />} onClick={() => this.handleLogin()} />
            </div>
          </div>

          <Footer />
        </div>

      </div>
    )
  }
}