import React, { Component } from 'react';
import Sidebar from './sidebar'
import Footer from './footer/index';
import Lives from './lives';
import News from './news';
import Links from './teste';
import { api } from './services/api'
import './app.css';

export default class App extends Component {
  state = {
    publicacaoLeiDados: ''
  }

  async componentDidMount() {
    await api.get("/publicacaoLei")
      .then(response => {
        this.setState({ publicacaoLeiDados: response.data.publicacaoLei[0] })
      })
      .catch(err => {
        console.log(err);
      });
  }

  render() {
    return (<div>
      <Sidebar />
      <div className='flex vertical' style={{ width: '80%', margin: '0 auto', minHeight: '300px', justifyContent: 'center' }}>
        <br />
        <strong style={{ fontSize: '1.4rem' }}>Diretoria Municipal de Cultura</strong><br />

        <div style={{ fontSize: '1.2rem' }}>
          A Diretoria Municipal de Cultura faz parte da Secretaria de
          Integração Social, Esporte e Cultura, com a dedicação às ações
          do poder público para o desenvolvimento artístico da Cidade.
          Este órgão atua no fomento das atividades culturais, apoio às
          iniciativas da sociedade e também a preservação do patrimônio
          histórico. Sob a gestão da Diretoria estão centros culturais,
          unidades de ensino artístico, museus, eventos e grupos de
          excelência, como coro, orquestras e corpo de baile. Sejam bem vindos!
        </div><br /><br />
        <Lives />
        <br /><br />
        {/* <button class="learn-more">
          <span class="circle" aria-hidden="true">
            <span class="icon arrow"></span>
          </span>
          <a style={{ fontSize: '1.3rem', textDecoration: 'none', color: 'black' }} href='https://bit.ly/Oficina_FMC_2020_ZOOM_30-11-20' target='_blanc'>
            <span class="button-text"> Oficina FMC 2020 - Pela plataforma ZOOM às 19h</span>
          </a>
        </button> 
        <p style={{ fontSize: '1rem' }}>Senha de acesso: 398896</p>*/}
      </div>

      {/* <News /> */}
      <Links />
      <Footer />
    </div >
    )
  }
}