import React from 'react'

const InputSimples = ({ placeHolder, type, label, value, id, disabled, escondido, onClick, maxLength, onChange, error }) => (
    <div className={type === 'cpf' ? 'Input-Simples flex vertical grid-1' : type === 'inputModal' ? 'Input-Simples-Modal flex horizontal grid-1' : type === 'text' ? 'Input-Simples-Text flex vertical grid-2' : type === 'cep' ? 'Input-Simples-cep flex vertical' : 'Input-Simples flex vertical grid-3'}>
        {label && (disabled ? (<label style={{ color: '#C2C2C2' }}>{label}</label>) : (<label>{label}</label>))}
        {error && (<small className='small-danger'>{error}</small>)}

        <input
            disabled={disabled}
            placeholder={placeHolder ? placeHolder : ''}
            type={type}
            id={id}
            maxLength={maxLength}
            value={value}
            style={escondido ? { visibility: 'hidden' } : { visibility: '' }}
            onChange={onChange}
            className={`inputcep ${error ? 'input-error' : ''}`} />
    </div>
)

export default InputSimples