import axios from "axios";
import { getToken } from "./auth";
export var apiURL = "https://api.anapolis.go.gov.br/apiCultura";


export const api = axios.create({
  baseURL: apiURL
});

export const apiImagem = axios.create({
  baseURL: 'https://api.anapolis.go.gov.br/apiCultura'
})

export const apiBusca = axios.create({
  baseURL: apiURL
});

apiBusca.interceptors.request.use(async config => {
  const token2 = getToken();
  if (token2) {
    config.headers.Authorization = token2;
  }
  return config;
});

api.interceptors.request.use(async config => {
  const token = getToken();
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

export default api;
