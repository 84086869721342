import React, { Component } from 'react'
import './button.css'

export default class Link extends Component {
  render() {
    return (
      <div>
        <button class="learn-more">
          <span class="circle" aria-hidden="true">
            <span class="icon arrow"></span>
          </span>
          <a style={{ fontSize: '1.3rem', textDecoration: 'none', color: 'black' }} href='https://bit.ly/Oficina_FMC_2020_ZOOM_30-11-20' target='_blanc'>
            <span class="button-text"> Oficina FMC 2020 - Pela plataforma ZOOM às 19h</span>
          </a>
        </button>
      </div>
    )
  }
}