import React, { Component } from 'react'
import api from '../../services/api'
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';

export default class LiteraturaEscrita extends Component {
  state = {
    produtoCultural: [],
    arrayMap: false,
  }

  async componentDidMount() {
    await api.get("/api/v1/culturalProduct/category?category=Literatura Escrita")
      .then(response => {
        this.setState({ produtoCultural: response.data.culturalProduct, arrayMap: true })
      })
      .catch(err => {
        console.log(err);
      });
  }

  render() {
    const { produtoCultural, arrayMap } = this.state
    const ListItemLink = (props) => {
      return <ListItem button component="a" {...props} />;
    }

    const linkPublication = `https://api.anapolis.go.gov.br/apiCultura/culturalProducts/`

    const removeAcento = (string) => {
      const final = string.normalize('NFD').replace(/[\u0300-\u036f]/g, "").toLowerCase()
      return final
    }

    return (
      <div>
        <div>
          <List component="nav" aria-label="main mailbox folders">

            {(arrayMap == false ? [] : produtoCultural).map((step, index) =>
              <ListItemLink href={linkPublication + removeAcento(step.category) + '/' + step.file} target="_blank">

                {(produtoCultural[index].file.substring(produtoCultural[index].file.indexOf('.')) === '.pdf') ?
                  <PictureAsPdfIcon style={{ color: 'red' }} />
                  :
                  <PictureAsPdfIcon style={{ color: 'blue' }} />
                }

                &nbsp;<ListItemText primary={step.author} />
              </ListItemLink>

            )
            }
          </List>
        </div>
      </div>
    )
  }
}