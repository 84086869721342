import React, { Component } from 'react';
import Menu from '../menu';
import './style.css'

export default class SlideIndexShow extends Component {
  render() {
    return (
      <div>
        <Menu />
        <div className="container-slide-index">
          <p className="titulo">Detalhes da notícia</p>
          <hr />
        </div>

      </div>
    )
  }
}