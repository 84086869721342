import React, { Component } from 'react';
import NavBar from '../navbar/index';
import InscricaoIndex from './inscricao';
import Banner from '../header/banner';
import Footer from '../footer/index';
import './style.css';
import 'bootstrap/dist/css/bootstrap.min.css';

export default class Inscricoes extends Component {
  render() {
    document.title = 'Mostra de Teatro - Sec. Cultura'
    return <div>
      <header><Banner /></header>
      <NavBar />
      <div className="public">
        <InscricaoIndex />
      </div>
      <Footer />
    </div>
  }
}