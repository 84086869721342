import React, { Component } from 'react';
import './style.css';

export default class Banner extends Component {
  render() {
    return <div >

      <img className="mostra-banner-img" src='/img/bannerTeatro.jpg' alt="" />

    </div >;
  }
}