import React, { Component } from 'react';
import Menu from '../menu';
import api, { apiURL } from '../../services/api';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import './style.css';
import ModalUpdate from './modalUpdate';

export default class InscricoesLei extends Component {

  constructor(props) {
    super(props);
    this.loadInscricoesLei = this.loadInscricoesLei.bind(this);
  }

  state = {
    inscricoesLei: '',
    show: false,
    inscricoesLeiById: '',
    buttonDetalhes: '',
  };

  componentDidMount() {
    this.loadInscricoesLei();
  }

  openModal() {
    this.setState({ show: true })
  }

  async loadInscricoesLei() {
    await api.get("/inscricoesLei")
      .then(response => {
        this.setState({
          inscricoesLei: response.data
        })
      })
      .catch(err => {
        console.log(err);
      });
  };

  incisoFormatter(cell, row) {
    return (
      cell === '2' ? 'Insciso II' : 'Inciso III'
    )
  }
  modalidadeFormatter(cell, row) {
    switch (cell) {
      case '1':
        return 'Grande Porte'
      case '2':
        return 'Médio Porte'
      case '3':
        return 'Pequeno Porte'
      default:
        return ''
    }
  }

  faixaFormater(cell, row) {
    switch (cell) {
      case '1':
        return 'Faixa 1'
      case '2':
        return 'Faixa 2'
      case '3':
        return 'Faixa 3'
      default:
        return ''
    }
  }

  modalidadeIncisoIIIFormater(cell, row) {
    switch (cell) {
      case '1':
        return 'Artes visuais'
      case '2':
        return 'Audiovisual'
      case '3':
        return 'Cultura de rua - Cultura afro-brasileira e capoeira'
      default:
        return ''
    }
  }


  render() {

    const btnActionsDocumentacao = (cell, row) => {
      return (
        <div>
          { cell !== null &&
            <div className="btn-arquivos" onClick={() => window.open(`${apiURL}/filesInscricoes/inscricoesLei/inciso2/${cell}`, '_blank')} size="small" aria-label="add"> <i class="fa fa-file" aria-hidden="true"></i></div>
          }
        </div>
      )
    }
    const btnActionsAnexo = (cell, row) => {

      return (
        <div>
          { cell !== null &&
            <div className="btn-arquivos" onClick={() => window.open(`${apiURL}/filesInscricoes/inscricoesLei/inciso3/${cell}`, '_blank')} size="small" aria-label="add"> <i class="fa fa-file" aria-hidden="true"></i></div>
          }
        </div>
      )
    }
    const btnActionsTrabalho = (cell, row) => {

      return (
        <div>
          { cell !== null &&

            <div className="btn-arquivos" onClick={() => window.open(`${apiURL}/filesInscricoes/inscricoesLei/inciso3/${cell}`, '_blank')} size="small" aria-label="add"> <i class="fa fa-file" aria-hidden="true"></i></div>

          }
        </div>
      )
    }

    const isExpandableRow = (cell) => {
      if (cell.id === this.state.buttonDetalhes) return true;
      else return false;
    }
    const renderModalidadeIII = (row) => {
      switch (row) {
        case '1':
          return 'ARTES VISUAIS - Individual ou duplas e coletivos artísticos'
        case '2':
          return 'AUDIOVISUAL'
        case '3':
          return 'CULTURA DE RUA - Cultura afro-brasileira e capoeira'
        case '4':
          return 'CULTURA DE RUA - Hip-Hop'
        case '5':
          return 'CULTURA POPULAR E ARTESANATO'
        case '6':
          return 'DANÇA'
        case '7':
          return 'LITERATURA - Escrita - prosa ou poesia'
        case '8':
          return 'LITERATURA - Poesia falada - vídeo'
        case '9':
          return 'ECONOMIA CRIATIVA E CULTURA DIGITAL'
        case '10':
          return 'MÚSICA - Autoral'
        case '11':
          return 'MÚSICA - Podcast'
        default:
          return ''
      }
    }

    const expandComponent = (row) => {
      return (
        <div className='expand-rows flex vertical'>
          <div><strong>Faixa da inscrição: </strong>{row.faixa === '1' ? 'Faixa 1' : row.faixa === '2' ? 'Faixa 2' : row.faixa === '3' ? 'Faixa 3' : ''}</div>
          { row.modalidade && <div><strong>Modalidade: </strong>{row.faixa === '1' ? 'Grande porte' : row.faixa === '2' ? 'Médio porte' : row.faixa === '3' ? 'Pequeno porte' : ''}</div>}
          { row.modalidade_inciso_III &&
            <div>
              <strong>Modalidade: </strong>
              {renderModalidadeIII(row.modalidade_inciso_III)}
            </div>}
          { row.links && <div><strong>Links: </strong>{row.links}</div>}
        </div>
      )
    }

    const btnActionsDetalhes = (cell, row) => {
      return (
        <div>
          <button onClick={() => (isExpandableRow(cell), this.setState({ buttonDetalhes: cell }))}><i class="fa fa-angle-double-down" aria-hidden="true"></i></button>
        </div>
      )

    }

    const options = {
      exportCSVText: 'Exportar em Excel',
      insertBtn: this.createCustomInsertButton,
      sizePerPageList: [{
        text: '5', value: 5
      }, {
        text: '10', value: 10
      }, {
        text: 'All', value: 50
      }]
    };
    const defaultSorted = [{
      dataField: 'name',
      order: 'desc'
    }];
    return (
      <div>
        <Menu />

        <div className="produto-lista" >
          <BootstrapTable
            data={this.state.inscricoesLei.inscricoes}
            striped
            expandableRow={isExpandableRow}
            expandComponent={expandComponent}
            hover
            options={options}
            condensed
            pagination
            keyField="name"
            search
            searchPlaceholder="Procurar..."
            sort="true"
            exportCSV
            defaultSorted={defaultSorted}
            columns={this.state.inscricoesLei}
          >
            <TableHeaderColumn width='25%' dataField='nome_representante'>Nome</TableHeaderColumn>
            <TableHeaderColumn width='15%' dataField='cpf'>CPF / CNPJ</TableHeaderColumn>
            <TableHeaderColumn width='15%' dataField='inciso_inscricao' dataFormat={this.incisoFormatter}>Inciso da inscrição</TableHeaderColumn>
            <TableHeaderColumn width='15%' dataField='file_lei_aldir_blanc_inciso_II' dataFormat={btnActionsDocumentacao}>Documentação</TableHeaderColumn>
            <TableHeaderColumn width='15%' dataField='file_lei_aldir_blanc_inciso_III_anexo' dataFormat={btnActionsAnexo}>Anexos</TableHeaderColumn>
            <TableHeaderColumn width='10%' dataField='file_lei_aldir_blanc_inciso_III_trabalho' dataFormat={btnActionsTrabalho}>Trabalhos</TableHeaderColumn>
            <TableHeaderColumn width='5%' dataField='id' dataFormat={btnActionsDetalhes}></TableHeaderColumn>
            <TableHeaderColumn width='0%' dataField='faixa'></TableHeaderColumn>
            <TableHeaderColumn width='0%' dataField='modalidade'></TableHeaderColumn>
            <TableHeaderColumn width='0%' dataField='modalidade_inciso_III'></TableHeaderColumn>
            <TableHeaderColumn width='0%' dataField='links'></TableHeaderColumn>
          </BootstrapTable>

          <ModalUpdate
            show={this.state.show}
            host={"/inscricoes/"}
            onHide={() => this.setState({ show: false })}
            id={this.state.inscricoesLeiById.id}
            url={this.state.inscricoesLeiById.url}
            ext={'doc'}
          />
        </div>
      </div>
    )
  }
}