import React, { Component } from 'react'
import './index.css'


export default class TextoIndex extends Component {

  render() {
    return (
      <div className='container-teste'>
        <div className="Container-Texto-Index">
          <div style={{ textAlign: 'center' }}>
            <img src='https://cdn.anapolis.go.gov.br/img/cultura/aldirblanc-logo.png' />
          </div>
          <br />
          <h2>Sobre a Lei Aldir Blanc</h2>
          <br />
          <p>A Lei nº14.017 do Governo Federal viabiliza
          o auxílio à classe artística brasileira que sofre
          restrições às suas atividades no período pandêmico.
          Esta lei ganhou o nome Aldir Blanc em homenagem
          ao compositor e letrista carioca, notabilizado
          por canções de grande sucesso nacional,
          e que foi vítima fatal do novo coronavírus
          no início de maio deste ano. São 3 bilhões
          de reais da União destinados a Estados e Municípios
      para o auxílio aos agentes e espaços culturais.</p>
          <br />
          <h2>Sobre os incisos</h2>
          <h5>Os principais benefícios previstos na Lei Aldir Blanc estão nos três incisos de seu artigo 2º. </h5>
          <br />
          <p>
            <ul>
              <li>
                Inciso I – Prevê auxílio em três parcelas de 600 reais ou 1,2 mil mães que sustentam sozinhas as despesas da família. As principais exigências para ter acesso a este benefício é não ter sido contemplado pelo auxílio emergencial do Governo Federal e ter atuado comprovadamente no setor há pelo menos dois anos. O auxílio previsto por este inciso é feito pelos Estados.<br /><br />
              </li>
              <li>
                Inciso II – Este auxílio comtempla os espaços culturais que tiveram suas atividades prejudicadas devido as restrições em combate à pandemia. São previstos valores que podem variar de 3 a 10 mil reais, com a finalidade de ajudar nos custos de manutenção como água, luz, telefone, impostos atrasados, pagamento de funcionários e outras despesas. Este inciso é de competência dos Municípios.<br /><br />
              </li>
              <li>
                Inciso III -  São auxílios em diferentes modalidades para artistas e grupos. A competência deste inciso são de Estados e Municípios.<br /><br />
              </li>
            </ul>
          </p>


        </div>
      </div>

    )
  }

}

