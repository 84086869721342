import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';

export default function DenseAppBar({ titulo }) {

  return (
    <div>
      <AppBar className="titulos" position="static">
        <Toolbar style={{ backgroundColor: '#004680' }} variant="dense">
          <Typography variant="h6">
            {titulo}
          </Typography>
        </Toolbar>
      </AppBar>
    </div>
  );
}