import React, { Component } from 'react'
import LandingPage from './LandingPage/landingPage'
import Sidebar from '../sidebar'
import LinksDownload from './LandingPage/linksDownload'
import Calendario from './LandingPage/calendario'
import Contato from './LandingPage/contato'
import Link from '../components/Button/Link'

export default class FMC20 extends Component {
  render() {
    return (
      <div>
        <Sidebar />
        <div className='fmc20'>
          <br /><br />
          {/* <Link />
          <p style={{ fontSize: '1.1rem', textAlign: 'center' }}>Senha de acesso: 398896</p> */}
          <LandingPage />
          <div className='links-download'>
            <LinksDownload />
          </div>
          <Calendario />
          <Contato />
        </div>
      </div>
    )
  }
}