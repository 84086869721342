import React, { Component } from 'react'
import api from './services/api'

export default class Lives extends Component {
  state = {
    lives: ''
  }

  async componentDidMount() {
    await api.get("/lives")
      .then(response => {
        this.setState({ lives: response.data.lives[0] })
      })
      .then(response => {
        this.setState({ arrayMap: true })
      })
      .catch(err => {
        console.log(err);
      });
  }

  render() {
    const { lives } = this.state;
    return (
      <div>
        <hr />
        { lives.disponibilidade === '1' &&
          <div style={{ marginBottom: '30px' }}>
            <p style={{ fontSize: '1.3rem', fontWeight: 'bold' }}>{lives.title}</p>
            <iframe width="100%" height="315" src={`https://www.youtube.com/embed/${lives.url}?autoplay=1`} frameborder="0" allow="accelerometer; autoplay=1; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
          </div>
        }
      </div>
    )
  }
}