import React, { Component } from 'react'
import Sidebar from '../sidebar'
import Titulo from '../components/Textos/titulo'
import './index.css'

export default class InformesUrgentes extends Component {

  render() {
    return (
      <div>
        <Sidebar />
        <br />
        <div className='Informes'>
          <Titulo titulo={'Agenda Cultural'} />
          <br />
          <ul>
            <li>
              <a href="#">
                <h2>Titulo 1</h2>
                <p>Lorem ipsum dolor sit amet. Aut porro Quis et nisi suscipit id sequi sunt! Eum officia nostrum sit autem molestiae vel natus aliquid est enim dignissimos rem voluptatem recusandae ut voluptatem velit. Sit fuga consequatur hic velit eius nam quia nobis in numquam blanditiis et aliquid facilis. Non obcaecati aperiam ab voluptates eveniet non similique repellat.</p>
              </a>
            </li>
            <li>
              <a href="#">
                <h2>Titulo 2</h2>
                <p>Ut sunt dolor non enim id omnis necessitatibus est distinctio ipsa. In sunt voluptatibus a odit dolorem eos nulla rerum id omnis necessitatibus et officia nesciunt sit nostrum sequi ex consequatur rerum?<br /><br />

Cum odit repellat est excepturi rerum eos soluta dolor et ipsum expedita ut fugit nihil quo impedit quia eos voluptates ipsa. Sed impedit eveniet aut earum omnis et placeat minima ea ratione assumenda.</p>
              </a>
            </li>
            <li>
              <a href="#">
                <h2>Titulo 3</h2>
                <p>Lorem ipsum dolor sit amet. Aut porro Quis et nisi suscipit id sequi sunt! Eum officia nostrum sit autem molestiae vel natus aliquid est enim dignissimos rem voluptatem recusandae ut voluptatem velit. Sit fuga consequatur hic velit eius nam quia nobis in numquam blanditiis et aliquid facilis. Non obcaecati aperiam ab voluptates eveniet non similique repellat.</p>
              </a>
            </li>
            <li>
              <a href="#">
                <h2>Titulo 4</h2>
                <p>Ut sunt dolor non enim id omnis necessitatibus est distinctio ipsa. In sunt voluptatibus a odit dolorem eos nulla rerum id omnis necessitatibus et officia nesciunt sit nostrum sequi ex consequatur rerum?<br /><br />

Cum odit repellat est excepturi rerum eos soluta dolor et ipsum expedita ut fugit nihil quo impedit quia eos voluptates ipsa. Sed impedit eveniet aut earum omnis et placeat minima ea ratione assumenda.</p>
              </a>
            </li>
            <li>
              <a href="#">
                <h2>Titulo 5</h2>
                <p>Lorem ipsum dolor sit amet. Aut porro Quis et nisi suscipit id sequi sunt! Eum officia nostrum sit autem molestiae vel natus aliquid est enim dignissimos rem voluptatem recusandae ut voluptatem velit. Sit fuga consequatur hic velit eius nam quia nobis in numquam blanditiis et aliquid facilis. Non obcaecati aperiam ab voluptates eveniet non similique repellat.</p>
              </a>
            </li>
            <li>
              <a href="#">
                <h2>Titulo 6</h2>
                <p>Ut sunt dolor non enim id omnis necessitatibus est distinctio ipsa. In sunt voluptatibus a odit dolorem eos nulla rerum id omnis necessitatibus et officia nesciunt sit nostrum sequi ex consequatur rerum?<br /><br />

Cum odit repellat est excepturi rerum eos soluta dolor et ipsum expedita ut fugit nihil quo impedit quia eos voluptates ipsa. Sed impedit eveniet aut earum omnis et placeat minima ea ratione assumenda.</p>
              </a>
            </li>
          </ul>

          <div>
            <div>
              <div>

              </div>
            </div>
            <div></div>
          </div>
        </div>


      </div>
    );
  }
}