import React, { Component } from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import './style.css';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Switch from '@material-ui/core/Switch';
import { cpfMask } from './mask';
import Button from '@material-ui/core/Button';

export default class Main extends Component {

  state = {
    checkedA: false,
    checkedB: true,
    botoesDisabled: false,
    botoes: true,
  };
  handleChange = (event) => {
    this.setState({
      ...this.state, [event.target.name]: event.target.checked
    });
    this.setState({
      ...this.state, [event.target.name]: event.target.checked
    })
  };

  handleChangeButton = (event) => {
    if (this.state.checkedA) {
      return <button>Loading...</button>
    }
  }

  render() {

    const {
      cpf,
      tipoInscricao,
      nomeArtistico,
      nomeCompleto,
      rg,
      dataNascimento,
      nomeMae,
      cep,
      logradouro,
      n,
      complemento,
      bairro,
      cidade,
      uf,
      email,
      categoria,
      telefone,
      uploadedFiles
    } = this.props

    async function handleSubmit(event) {
      event.preventDefault();
      const form = event.target;
      const data = new FormData(form);

      function stringifyFormData(fd) {
        const data = {
          'cpf': cpf,
          'tipo_inscricao': tipoInscricao,
          'nome_artistico': nomeArtistico,
          'nome_completo': nomeCompleto,
          'rg': rg,
          'data_nascimento': dataNascimento,
          'nome_mae': nomeMae,
          'cep': cep,
          'logradouro': logradouro,
          'n': n,
          'complemento': complemento,
          'bairro': bairro,
          'cidade': cidade,
          'uf': uf,
          'email': email,
          'categoria': categoria,
        };
        for (let key of fd.keys()) {
          data[key] = fd.get(key);
        }
        return JSON.stringify(data, null, 2);

      }

      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var raw = stringifyFormData(data);
      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow"
      };

      fetch("http://localhost:3001/inscricoes", requestOptions)
        .then(response => response.text())
        .then(responseText => responseText)
        .catch(error => error);
      return (window.location = "/")
    }
    return (
      <div>
        <form className="form" onSubmit={handleSubmit}>
          <div className="inscricao-lista" >
            <AppBar className="titulos" position="static">
              <Toolbar variant="dense">
                <Typography variant="h6">
                  ENVIAR INSCRIÇÃO
          </Typography>
              </Toolbar>
            </AppBar>
            <br></br>



      Confira os dados da sua inscrição e arquivos enviados antes de enviar o formulário.

        <List>
              <div className="list">
                <ListItem>
                  <ListItemText primary="Tipo" secondary={tipoInscricao} />
                </ListItem>
                <ListItem id="cpf" value={cpf}>
                  <ListItemText id="cpf" primary="CPF" secondary={cpfMask(cpf)} value={cpf} />
                </ListItem>
                <ListItem>
                  <ListItemText primary="Nome artistico" secondary={nomeArtistico} />
                </ListItem>
              </div>
              <div className="list">
                <ListItem>
                  <ListItemText primary="Nome completo" secondary={nomeCompleto} />
                </ListItem>
                <ListItem>
                  <ListItemText primary="Nome da mãe" secondary={nomeMae} />
                </ListItem>
                <ListItem>
                  <ListItemText primary="Data de nascimento" secondary={dataNascimento} />
                </ListItem>
              </div>
              <div className="list">
                <ListItem>
                  <ListItemText primary="RG" secondary={rg} />
                </ListItem>
                <ListItem>
                  <ListItemText primary="Endereço" secondary={logradouro} />
                </ListItem>
                <ListItem>
                  <ListItemText primary="Estado" secondary={uf} />
                </ListItem>
              </div>
              <div className="list">
                <ListItem>
                  <ListItemText primary="Cidade" secondary={cidade} />
                </ListItem>
                <ListItem>
                  <ListItemText primary="CEP" secondary={cep} />
                </ListItem>
                <ListItem>
                  <ListItemText primary="E-mail" secondary={email} />
                </ListItem>
              </div>
              <div className="list">
                <ListItem>
                  <ListItemText primary="Telefone" secondary={telefone} />
                </ListItem>
                <ListItem>
                  <ListItemText primary="Categoria" secondary={categoria} />
                </ListItem>
                <ListItem>
                  <ListItemText primary="Ficha e Documentação" secondary={uploadedFiles} />
                </ListItem>
              </div>
              <div className="list">
                <ListItem>
                  <ListItemText primary="Currículo" secondary="July 20, 2014" />
                </ListItem>
                <ListItem>
                  <ListItemText primary="Portifólio" secondary="July 20, 2014" />
                </ListItem>
                <ListItem>
                  <ListItemText primary="Projeto" secondary={''} />
                </ListItem>
              </div>
              <div className="list">
                <ListItem>
                  <ListItemText primary="Imagens" secondary="July 20, 2014" />
                </ListItem>
              </div>
            </List>

            <Switch
              checked={this.state.checkedA}
              onChange={this.handleChange}
              color="primary"
              name="checkedA"
              inputProps={{ 'aria-label': 'primary checkbox' }}
            />
            <Button disabled={!this.state.checkedA === true} type="submit" variant="contained" className={(this.state.checkedA === true ? 'botoes' : 'hidden')} disableElevation>
              Confirmar Inscrição
        </Button>
          </div>
        </form>
      </div>
    )
  }
}
