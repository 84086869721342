import React, { Component } from 'react';
import Menu from '../menu';
import api from '../../services/api';
import { BootstrapTable, TableHeaderColumn, InsertButton } from 'react-bootstrap-table';
import './style.css';
import Fab from '@material-ui/core/Fab';
import EditIcon from '@material-ui/icons/Edit';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import CheckIcon from '@material-ui/icons/Check';
import ModalUpdate from './modalUpdate';

export default class Lives extends Component {

  state = {
    lives: '',
    show: false,
    livesById: ''
  };

  async componentDidMount() {
    await this.loadLives();
  }

  openModal() {
    this.setState({ show: true })
  }

  async loadLives() {
    await api.get("/lives")
      .then(response => {
        this.setState({
          lives: response.data.lives
        })
      })
      .catch(err => {
        console.log(err);
      });
  };

  render() {
    const disponibilidade = this.state.lives[0] ? this.state.lives[0].disponibilidade : ''
    const btnActions = (cell, row) => {

      const handleUpdate = async userId => {

        const requestOptions = {
          method: 'GET'
        };

        await api.get("/lives/" + cell, requestOptions)
          .then(response => this.setState({ livesById: response.data.lives }))
          .catch(error => { console.log(error) });
        this.setState({ show: true })
        // setTimeout(handleClick, 2000)
      }



      const handleClick = async cell => {

        await api.put("/lives/" + 1, { disponibilidade: 'false' })

        return (document.location.reload(true))
        // setTimeout(handleClick, 2000)
      }
      const handleClickActive = async cell => {

        await api.put("/lives/" + 1, { disponibilidade: 'true' })

        return (document.location.reload(true))
        // setTimeout(handleClick, 2000)
      }
      return (
        <div>
          <Fab className="btn-editar" onClick={() => handleUpdate(cell)} size="small" aria-label="add">
            <EditIcon />
          </Fab>
          {disponibilidade === '1' &&
            <Fab className="btn-excluir" onClick={() => handleClick(cell)} size="small" aria-label="add">
              <HighlightOffIcon />
            </Fab>}
          {disponibilidade === '0' &&
            <Fab className="btn-ativar" onClick={() => handleClickActive(cell)} size="small" aria-label="add">
              <CheckIcon />
            </Fab>}
        </div>
      )
    }

    return (
      <div>
        <Menu />

        <div className="produto-lista" >
          <BootstrapTable
            data={this.state.lives}
            striped
            hover
            condensed
            pagination
            keyField="name"
            sort="true"
            columns={this.state.lives}
          >
            <TableHeaderColumn width='25%' keyField="title" dataField='title'>Título</TableHeaderColumn>
            <TableHeaderColumn width='25%' dataField='url'>URL</TableHeaderColumn>
            <TableHeaderColumn width='15%' dataField='updatedAt'>Última atualização</TableHeaderColumn>
            <TableHeaderColumn width='15%' dataField='disponibilidade' dataFormat={btnActions}></TableHeaderColumn>
          </BootstrapTable>

          <ModalUpdate
            show={this.state.show}
            host={"/lives/"}
            title={this.state.lives}
            onHide={() => this.setState({ show: false })}
            id={1}
            url={this.state.lives[0]}
            type={'lives'}
          />
        </div>
      </div>
    )
  }
}