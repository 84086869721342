import React, { Component } from 'react';
import './dashboard.css';

export default class Dashboard extends Component {
  state = {
  }

  render() {

    return (
      <div className="content">
        <div className="grupo">
          <div className="menu-dashboard">
            <a style={{ textDecoration: 'none' }} href="http://www.anapolis.go.gov.br/portal/" target='_blank'>
              <img className="img-sidebar" src="/img/logo-prefeitura-de-anapolis---eleitoral(BRANCO).png" alt="anapolis" />

            </a>
          </div>

          <div className="subMenu-dashboard">
            {/* <a style={{ textDecoration: 'none' }} href="/dashboard/slideIndex">
              <div className="text-dashboard">Slide Index</div>
            </a>
            <a style={{ textDecoration: 'none' }} href="/dashboard/slideSalao">
              <div className="text-dashboard">Slide Salão de Arte</div>
            </a>
            <a style={{ textDecoration: 'none' }} href="/dashboard/calendario">
              <div className="text-dashboard">Calendário</div>
            </a>
            <a style={{ textDecoration: 'none' }} href="/dashboard/publicacoes">
              <div className="text-dashboard">Publicação</div>
            </a>
            <a style={{ textDecoration: 'none' }} href="/dashboard/downloads" >
              <div className="text-dashboard">Download</div>
            </a>
            <a style={{ textDecoration: 'none' }} href="/dashboard/inscricoes-lei" >
              <div className="text-dashboard">Inscrições Lei Aldir Blanc</div>
            </a>
            <a style={{ textDecoration: 'none' }} href="/dashboard/inscricoes-salao" >
              <div className="text-dashboard">Inscrições Salão de arte</div>
            </a> */}
            <a style={{ textDecoration: 'none' }} href="/dashboard/publicacaoLei">
              <div className="text-dashboard">Publicações Lei Aldir Blanc</div>
            </a>
            <a style={{ textDecoration: 'none' }} href="/dashboard/produtoCultural">
              <div className="text-dashboard">Produto Cultural</div>
            </a>
          </div>
        </div>


      </div>
    )
  }
}