import React, { Component } from 'react';
import Menu from '../menu';
import api from '../../services/api';
import { BootstrapTable, TableHeaderColumn, InsertButton } from 'react-bootstrap-table';
import './style.css';
import Fab from '@material-ui/core/Fab';
import EditIcon from '@material-ui/icons/Edit';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import ModalUpdate from './modalUpdate';

export default class PublicacaoLei extends Component {

  constructor(props) {
    super(props);
    this.loadPublicacaoLei = this.loadPublicacaoLei.bind(this);
  }

  state = {
    publicacaoLei: '',
    show: false,
    publicacaoLeiById: ''
  };

  componentDidMount() {
    this.loadPublicacaoLei();
  }

  openModal() {
    this.setState({ show: true })
  }

  async loadPublicacaoLei() {
    await api.get("/api/v1/publication")
      .then(response => {
        this.setState({
          publicacaoLei: response.data.publication
        })
      })
      .catch(err => {
        console.log(err);
      });
  };

  handleInsertButtonClick = (onClick) => {

    return (window.location = "publicacaoLei/cadastro")
  }

  createCustomInsertButton = (onClick) => {
    return (
      <InsertButton
        btnText='Novo registro'
        btnContextual='btn-primary'
        className='my-custom-class'
        btnGlyphicon='glyphicon-edit'
        onClick={() => this.handleInsertButtonClick(onClick)} />
    );
  }

  render() {
    const getToken = () => {
      const token1 = localStorage.getItem('token1');
      const token2 = localStorage.getItem('token2');
      const token3 = localStorage.getItem('token3');
      if (!token1 || !token2 || !token3) return null;
      return `${token1}.${token2}.${token3}`;
    }
    const btnActions = (cell, row) => {

      const handleUpdate = async userId => {

        const requestOptions = {
          method: 'GET'
        };

        await api.get("/publicacaoLei/" + cell, requestOptions)
          .then(response => this.setState({ publicacaoLeiById: response.data.publicacaoLei }))
          .catch(error => { console.log(error) });
        this.setState({ show: true })
        // setTimeout(handleClick, 2000)
      }



      const handleClick = async cell => {

        const requestOptions = {
          method: 'DELETE'
        };

        const myHeaders = {
          'authorization': `Bearer ${getToken()}`
        }


        if (window.confirm("Você realmente deseja remover esta publicação?")) {
          await api.delete(`/api/v1/publication/${cell}`, {
            headers: myHeaders
          })
            .then(response => { return response.json(); })
            .catch(error => { console.log(error) });

          return (document.location.reload(true))
          // setTimeout(handleClick, 2000)
        }


      }

      return (
        <div>
          <Fab className="btn-excluir" onClick={() => handleClick(cell)} size="small" aria-label="add">
            <DeleteForeverIcon />
          </Fab>
        </div>
      )
    }

    const options = {
      exportCSVText: 'Exportar em Excel',
      insertBtn: this.createCustomInsertButton,
      sizePerPageList: [{
        text: '5', value: 5
      }, {
        text: '10', value: 10
      }, {
        text: 'All', value: 50
      }]
    };
    const defaultSorted = [{
      dataField: 'name',
      order: 'desc'
    }];
    return (
      <div>
        <Menu />

        <div className="produto-lista" >
          <BootstrapTable
            data={this.state.publicacaoLei}
            striped
            hover
            options={options}
            insertRow
            condensed
            pagination
            keyField="name"
            search
            searchPlaceholder="Procurar..."
            sort="true"
            exportCSV
            defaultSorted={defaultSorted}
            columns={this.state.publicacaoLei}
          >
            <TableHeaderColumn width='25%' keyField="title" dataField='title'>Titulo</TableHeaderColumn>
            <TableHeaderColumn width='15%' dataField='category'>Categoria</TableHeaderColumn>
            <TableHeaderColumn width='5%' dataField='id' dataFormat={btnActions}></TableHeaderColumn>
          </BootstrapTable>

          <ModalUpdate
            show={this.state.show}
            host={'publicacaoLei/'}
            hostImages={'publicacaoLei/images/'}
            onHide={() => this.setState({ show: false })}
            title={this.state.publicacaoLeiById.title}
            id={this.state.publicacaoLeiById.id}
            url={this.state.publicacaoLeiById.url}
            ext={'doc'}
            type={'publicacaoLei'}
          />
        </div>
      </div>
    )
  }
}