import React, { Component } from 'react';
import './style.css';

export default class Footer extends Component {
  render() {
    return <div className='footer flex horizontal'>
      <div className='flex-1'>
        <div className="contato">
          <div>Diretoria Municipal de Cultura</div>
          <div>Praça Bom Jesus, nº 101, Setor Central</div>
          <div>Para mais informações:</div>
          <div>(62) 3902-1074 / 1111</div>
          <div>cultura@anapolis.go.gov.br</div>
        </div>
      </div>
      <div className='Img-Footer flex-1'>
        <img src="https://cdn.anapolis.go.gov.br/img/logos/sem_fundo/azuis/integracao.png" alt="logo" className="logo" />
      </div>
    </div>;
  }
}