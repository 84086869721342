import React, { Component } from 'react';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import './sidebar.css';

export default class Siderbar extends Component {
  state = {
    menuMb: false
  }
  render() {

    const toggleDrawer = (anchor, open) => (event) => {
      if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
        return;
      }

      this.setState({ [anchor]: open });
    };

    const itensMenu = () => {
      return (
        <div className="subMenu-dashboard">
          <a style={{ textDecoration: 'none' }} href="/">
            <p className="text-dashboard">Início</p>
          </a>
          <a style={{ textDecoration: 'none' }} href="/publicacoes">
            <p className="text-dashboard">Publicações Oficiais</p>            </a>
          {/* <a style={{ textDecoration: 'none' }} href="/informes" >
            <p className="text-dashboard">Agenda Cultural</p>
          </a> */}
          {/* <a style={{ textDecoration: 'none' }} href="/informes" target='_blank'>
              <p className="text-dashboard">Informes Urgentes</p>
            </a>
            <a style={{ textDecoration: 'none' }} href="/produto-cultural" target='_blank'>
              <p className="text-dashboard">Produto cultural</p>
            </a>
            <a style={{ textDecoration: 'none' }} href="/contato" target='_blank'>
              <p className="text-dashboard">Fale conosco</p>
            </a> */}
          <a style={{ textDecoration: 'none' }} href="/login" >
            <p className="text-dashboard">Login</p>
          </a>
        </div>
      )
    }

    return (
      <div className="header_index">
        <div className="conteiner-index">
          <div className="menu-dashboard">
            <a style={{ textDecoration: 'none' }} href="http://www.anapolis.go.gov.br/portal/" target='_blank'>
              <img className="img-sidebar" src="https://cdn.anapolis.go.gov.br/img/logos/logo_branco.png" alt="anapolis" />

            </a>
          </div>
          <div onClick={() => this.setState({ menuMb: !this.state.menuMb })} className='sidebar-mobile'>
            <i className="fa fa-bars" aria-hidden="true"></i>
          </div>
          <SwipeableDrawer
            anchor={'left'}
            open={this.state.menuMb}
            onClose={toggleDrawer('menuMb', false)}
            onOpen={toggleDrawer('menuMb', true)}
          >
            <div className='menu-mb'>
              <a style={{ textDecoration: 'none' }} href="/">
                <p className="text-dashboard"><i className="fa fa-home" aria-hidden="true"></i>  Início</p>
              </a>
              <a style={{ textDecoration: 'none' }} href="/publicacoes">
                <p className="text-dashboard"> <i className="fa fa-file" aria-hidden="true"></i> Publicações Oficiais</p>            </a>
              {/* <a style={{ textDecoration: 'none' }} href="/informes" >
                <p className="text-dashboard"> <i class="fa fa-book" aria-hidden="true"></i> Agenda Cultural</p>
              </a> */}
              <a style={{ textDecoration: 'none' }} href="/login" >
                <p className="text-dashboard"><i className="fa fa-unlock" aria-hidden="true"></i> Login</p>
              </a>
            </div>

          </SwipeableDrawer>
          {itensMenu()}
        </div>
      </div >
    )
  }
}