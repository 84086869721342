import React, { Component } from 'react';
import './style.css';
import 'bootstrap/dist/css/bootstrap.min.css';

export default class Header extends Component {
  render() {
    return <div className="mostra-header">

      <div className="mostra-header-text">
        <br></br>

        <p className="mostra-header-text">
          A Mostra de Teatro de Anápolis está em sua 27ª edição abrindo as portas para grupos da Cidade e de todo o País. Esta é uma realização da Prefeitura Municipal de Anápolis, por meio da Secretaria Municipal de Cultura.</p>
        <p>
          A Mostra de Teatro, evento criado em 1983, apresenta-se como um instrumento de fomento e valorização da produção cênica, a partir do interior goiano para o País. O evento é uma oportunidade para aprimorar conhecimentos, desenvolver a cultura teatral, revelar talentos e promover o intercâmbio artístico-cultural.
        </p>
      </div>
      <div className="mostra-header-text2">
        ORIENTAÇÕES PARA INSCRIÇÃO<br></br>
        Fazer o Download do Edital e modelos para documentação<br></br>
        Preencher Informações Pessoais<br></br>
        Anexar a Documentação e Enviar<br></br>
        Site melhor visualizado com o Firefox
      </div>

    </div >;
  }
}