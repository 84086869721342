import React, { Component } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';

export default class LinksDownload extends Component {

  render() {
    const useStyles = makeStyles((theme) => ({
      root: {
        width: '100%',
        maxWidth: 560,
        backgroundColor: theme.palette.background.paper,
      },
    }));

    const ListItemLink = (props) => {
      return <ListItem button component="a" {...props} />;
    }

    return (
      <div className='Links-Download'>
        <div className={useStyles.root}>
          <List component="nav" aria-label="main mailbox folders">

            <ListItemLink href={'/img/Regulamento_FMC_2020.pdf'} target="_blank">
              <PictureAsPdfIcon style={{ color: 'red' }} />
              &nbsp;<ListItemText primary={'REGULAMENTO FMC 2020'} />
            </ListItemLink>
            <ListItemLink href={'/img/Retificacao_regulamento.pdf'} target="_blank">
              <PictureAsPdfIcon style={{ color: 'red' }} />
              &nbsp;<ListItemText primary={'I RETIFICAÇÃO DO REGULAMENTO'} />
            </ListItemLink>
            <ListItemLink href={'/img/ANEXO_I.doc'} target="_blank">
              <PictureAsPdfIcon style={{ color: 'blue' }} />
              &nbsp;<ListItemText primary={'FORMULÁRIO PARA INSCRIÇÃO DO PROJETO '} />
            </ListItemLink>
            <ListItemLink href={'/img/ANEXO_II.doc'} target="_blank">
              <PictureAsPdfIcon style={{ color: 'blue' }} />
             &nbsp; <ListItemText primary={'TERMO DE ACEITE DE PARTICIPAÇÃO - TERMO DE ANUÊNCIA DE ESPAÇOS - TERMO DE AUTORIZAÇÃO DE DIREITOS AUTORAIS - DECLARAÇÃO DE RESIDÊNCIA'} />
            </ListItemLink>
            <ListItemLink href={'/Files/alteracoes_recursos_calendario.pdf'} target="_blank">
              <PictureAsPdfIcon style={{ color: 'red' }} />
              &nbsp;<ListItemText primary={'ALTERAÇÕES RECURSOS E CALENDÁRIO'} />
            </ListItemLink>
            <ListItemLink href={'/Files/ata_habilitacao_fmc2021.pdf'} target="_blank">
              <PictureAsPdfIcon style={{ color: 'red' }} />
              &nbsp;<ListItemText primary={'ATA DE HABILITAÇÃO - FUNDO MUNICIPAL DE CULTURA 2021 - RETIFICADA'} />
            </ListItemLink>

          </List>
        </div>
      </div>
    )
  }
}